import { Box } from '@mui/system';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { theme } from 'styles/theme';
import { typography } from 'styles/typography';

export type PieChartValueOptions = {
  value: any;
  name: string;
  color?: string;
};

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: any;
  payload?: any;
}) => {
  if (active && payload && payload.length) {
    const value = Number(payload[0].value || 0).toFixed(0);
    return (
      <Box
        sx={{ borderRadius: '4px' }}
        bgcolor={theme.colors.offWhite}
        px={1}
        color={theme.colors.darkBlue}
        fontSize={'small'}
      >
        <Box fontWeight='bold' component={'span'}>
          {payload[0].name}:{' '}
        </Box>
        {value}%
      </Box>
    );
  }

  return null;
};

export const PieChartStraightAngle = ({
  data,
}: {
  data: PieChartValueOptions[];
}) => {
  return (
    <ResponsiveContainer minHeight={170}>
      <PieChart>
        <Pie
          stroke='none'
          data={data}
          width={190}
          cy={110}
          startAngle={180}
          endAngle={0}
          innerRadius={87}
          outerRadius={100}
          fill='#FF0000'
          dataKey='value'
          nameKey='name'
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Legend
          iconType='circle'
          wrapperStyle={{ fontSize: typography.fontSize['1'] }}
        />
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

import { Box, Toolbar } from '@mui/material';
import { useContext, useState } from 'react';
import { useGetFleetCII } from 'routes/environmental-monitor/services/fleet.service';
import {
  dateReferenceKeyProps,
  fleetCIIHistoricalType,
  fleetCIIKeyProps,
  getBoundaryDateOfProjection,
  getFleetCIIChartRangeValues,
} from 'routes/environmental-monitor/view-models/cii-ratings-chart.viewModel';
import { ChartKey } from 'shared/components/chart-key/chart-key.component';
import { ErrorComponent } from 'shared/components/error/error.component';
import { LoadingComponent } from 'shared/components/loading/loading.component';
import { FleetCIIRatingsBarChart } from './cii-ratings-barchart.component';
import { CIIHistoricalDataSelection } from './cii-ratings-historical-data-selection.component';
import { fleetPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { FixedTimeline } from 'assets/fixed-timeline';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';

export const CIIRatingsChart = ({
  dateRange,
}: {
  dateRange: DateRange | null;
}) => {
  const { imoNumbers } = useContext(NavBarContext);
  const ciiResponse = useGetFleetCII(dateRange, imoNumbers);
  const isLoading = ciiResponse.loading;

  const [historicalType, sethistoricalType] = useState(
    fleetCIIHistoricalType.YTD
  );

  const handleChangeHistoricalData = (
    selectedHistoricalType: fleetCIIHistoricalType
  ) => {
    sethistoricalType(selectedHistoricalType);
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <LoadingComponent data-testid='loading-component' height='415px' />
      );
    }

    const hasData =
      ciiResponse.data?.lastYAndYtd?.length !== undefined
        ? ciiResponse.data?.lastYAndYtd?.length > 0
        : false;
    if (ciiResponse.error || !hasData) {
      return <ErrorComponent error={ciiResponse.error} empty={!hasData} />;
    }

    const ciiValues = ciiResponse.data ? ciiResponse.data[historicalType] : [];
    const dataValues =
      ciiValues?.map((v: any) => v?.A + v?.B + v?.C + v?.D + v?.E) ?? [];

    const chartRangeValues = getFleetCIIChartRangeValues(dataValues);
    const boundaryDateOfProjection = getBoundaryDateOfProjection(ciiValues);

    return (
      <>
        <Toolbar
          disableGutters
          sx={{
            paddingLeft: 5,
            paddingRight: 5,
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <ChartKey items={fleetCIIKeyProps} />
          <Box>
            <CIIHistoricalDataSelection
              handleHistoricaDataChange={handleChangeHistoricalData}
            ></CIIHistoricalDataSelection>
          </Box>
        </Toolbar>

        {new UTCDate().isBeginningOfYear() && (
          <Toolbar disableGutters sx={{ paddingLeft: 5, marginTop: -2 }}>
            <ChartKey {...dateReferenceKeyProps} />
          </Toolbar>
        )}

        <Box>
          <FleetCIIRatingsBarChart
            key='ciiRating'
            name='ciiRating'
            ciiData={ciiValues}
            dataMin={chartRangeValues.dataMin}
            dataMax={chartRangeValues.dataMax}
            chartMax={chartRangeValues.chartMax}
            chartMaxOffset={chartRangeValues.chartMaxOffset}
            displayReferenceArea={true}
            historicalType={historicalType}
            projectDate={boundaryDateOfProjection}
          ></FleetCIIRatingsBarChart>
        </Box>
      </>
    );
  };

  return (
    <CardContainer
      title={fleetPageTitles.ciiRatings}
      icon={<FixedTimeline sx={{ margin: '5px 0px 0px 15px' }} />}
    >
      {renderContent()}
    </CardContainer>
  );
};

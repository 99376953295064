import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import {
  fleetCIIHistoricalType,
  fleetCIIHistoricalTypeLabel,
  getFleetCIIChartDomain,
  getFleetCIIChartXAxisTicks,
  fleetCIIYAxisTitle,
  xAxisTickCiiTrendFormatter,
} from '../../view-models/cii-ratings-chart.viewModel';
import { theme } from 'styles/theme';
import { FleetCiiDataPoint } from 'routes/environmental-monitor/models/vessel-cii-rating.model';
import { CustomTooltip } from './cii-ratings-tooltip.component';
import { getYAxisTicks } from 'routes/environmental-monitor/view-models/utils/graph-utils';
import { TopBarDates } from '../shared/topbar-dates.component';
import { UTCDate } from 'shared/utils/date-utc-helper';

export const FleetCIIRatingsBarChart = (props: {
  key: string;
  name: string;
  ciiData: FleetCiiDataPoint[];
  dataMin: number;
  dataMax: number;
  chartMax: number;
  chartMaxOffset: number;
  displayReferenceArea?: boolean;
  lineColor?: string;
  historicalType: string;
  projectDate: number | null;
}) => {
  const ciiData = props.ciiData;
  const chartData: {
    date: number;
    A?: number;
    B?: number;
    C?: number;
    D?: number;
    E?: number;
  }[] = [];

  if (ciiData) {
    chartData.push(
      ...[
        ...ciiData.map((item: any) => {
          return {
            date: item.date,
            A: item.A,
            B: item.B,
            C: item.C,
            D: item.D,
            E: item.E,
          };
        }),
      ]
    );
  }

  const domain = getFleetCIIChartDomain(props.historicalType);
  const projecDateOnDomain = new UTCDate().unixTime!;
  const xAxisTicks = getFleetCIIChartXAxisTicks(props.historicalType);
  const yAxisTicks = getYAxisTicks(props.dataMin, props.dataMax);

  const title: string =
    props.historicalType === fleetCIIHistoricalType.YTD
      ? fleetCIIHistoricalTypeLabel.YTD
      : fleetCIIHistoricalTypeLabel.LAST_YEAR_AND_YTD;

  // created some date buffer to better display the bars/data
  const minDate = new UTCDate(domain.min).dateDayjs?.subtract(3, 'day').unix()!;

  const maxDate = new UTCDate(domain.max).dateDayjs?.add(2, 'day').unix()!;

  return (
    <ResponsiveContainer width='100%' height={415}>
      <BarChart
        data={chartData}
        margin={{
          top: 42,
          bottom: 60,
          right: 50,
          left: 0,
        }}
      >
        {props.displayReferenceArea && (
          <>
            {TopBarDates({
              props: {
                currentTime: projecDateOnDomain,
                min: minDate,
                max: maxDate,
                dataMax: props.chartMaxOffset,
                chartMax: props.chartMax,
                leftLabel: title,
              },
            })}
          </>
        )}

        <XAxis
          dataKey='date'
          angle={-35}
          tick={{ fill: theme.colors?.offWhite }}
          type='number'
          ticks={xAxisTicks}
          textAnchor='end'
          tickFormatter={xAxisTickCiiTrendFormatter}
          fontSize={12}
          tickMargin={8}
          domain={[minDate, maxDate]}
        />

        <YAxis
          allowDataOverflow
          fontSize={12}
          dataKey='value'
          tickLine={false}
          tick={{ fill: props.lineColor ?? theme.colors?.offWhite }}
          tickCount={yAxisTicks.length}
          ticks={yAxisTicks}
          interval={0}
          tickMargin={10}
          type='number'
          domain={[props.dataMin, props.chartMax]}
          axisLine={false}
          width={75}
        >
          <Label
            dy={60}
            fontSize={12}
            fill={theme.palette.text.primary}
            position={'insideLeft'}
            offset={35}
            angle={-90}
            value={fleetCIIYAxisTitle}
          ></Label>
        </YAxis>
        <CartesianGrid
          color={theme.colors.white}
          vertical={false}
          strokeDasharray='0'
          opacity={0.14}
        />
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ fill: 'transparent' }}
          coordinate={{ x: 100, y: 140 }}
        ></Tooltip>

        <Bar
          dataKey='E'
          stackId='a'
          barSize={12}
          fill={theme.colors?.eRating}
        ></Bar>
        <Bar
          dataKey='D'
          stackId='a'
          barSize={12}
          fill={theme.colors?.dRating}
        ></Bar>
        <Bar
          dataKey='C'
          stackId='a'
          barSize={12}
          fill={theme.colors?.cRating}
        ></Bar>
        <Bar
          dataKey='B'
          stackId='a'
          barSize={12}
          fill={theme.colors?.bRating}
        ></Bar>
        <Bar
          dataKey='A'
          stackId='a'
          fill={theme.colors?.aRating}
          barSize={12}
        ></Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

import {
  ISequence,
  SequenceTypes,
} from 'routes/environmental-monitor/models/plannedItinerary.model';

export class SequenceValidationResult {
  public valid: boolean;
  public id: number;
  public message: string;
  public failingField: keyof ISequence | undefined;

  private constructor(
    valid?: boolean,
    id?: number,
    message?: string,
    failingField?: keyof ISequence
  ) {
    this.valid = valid ?? true;
    this.id = id ?? -1;
    this.message = message ?? '';
    this.failingField = failingField;
  }

  public static Passed(): SequenceValidationResult {
    return new SequenceValidationResult();
  }

  public static Failed(
    id: number,
    message: string,
    failingField?: keyof ISequence
  ): SequenceValidationResult {
    return new SequenceValidationResult(false, id, message, failingField);
  }
}

export interface ISequenceRule {
  Validate(
    lastSequence: ISequence,
    thisSequence: ISequence | undefined,
    isFinalSequence?: boolean
  ): SequenceValidationResult;
}

const passed = SequenceValidationResult.Passed();

export abstract class LoadingConditionConstant implements ISequenceRule {
  Validate(
    lastSequence: ISequence,
    thisSequence: ISequence | undefined
  ): SequenceValidationResult {
    if (
      !thisSequence ||
      lastSequence.loadingCondition === thisSequence.loadingCondition ||
      thisSequence.sequence === SequenceTypes.InPortDischarge ||
      thisSequence.sequence === SequenceTypes.InPortLoading
    ) {
      return passed;
    }
    return SequenceValidationResult.Failed(
      thisSequence.id,
      `The loading condition can only change with an "${SequenceTypes.InPortLoading}" or "${SequenceTypes.InPortDischarge}" operation.`,
      'loadingCondition'
    );
  }
}

export abstract class CargoConstant implements ISequenceRule {
  Validate(
    lastSequence: ISequence,
    thisSequence: ISequence | undefined
  ): SequenceValidationResult {
    if (
      !thisSequence ||
      lastSequence.cargoQuantity === thisSequence.cargoQuantity ||
      thisSequence.sequence === SequenceTypes.InPortDischarge ||
      thisSequence.sequence === SequenceTypes.InPortLoading
    ) {
      return passed;
    }
    return SequenceValidationResult.Failed(
      thisSequence.id,
      `The cargo amount can only change with an "${SequenceTypes.InPortLoading}" or "${SequenceTypes.InPortDischarge}" operation.`,
      'cargoQuantity'
    );
  }
}

export abstract class FinalSequenceIsInPort implements ISequenceRule {
  Validate(
    _lastSequence: ISequence,
    thisSequence: ISequence | undefined,
    isFinalSequence: boolean
  ): SequenceValidationResult {
    if (!isFinalSequence) {
      return passed;
    }

    const sequenceToCheck = thisSequence ?? _lastSequence;

    if (
      sequenceToCheck.sequence === SequenceTypes.InPortDischarge ||
      sequenceToCheck.sequence === SequenceTypes.InPortLoading
    ) {
      return passed;
    }
    return SequenceValidationResult.Failed(
      sequenceToCheck.id,
      `The final operation must be "${SequenceTypes.InPortLoading}" or "${SequenceTypes.InPortDischarge}".`,
      'sequence'
    );
  }
}

export const getSequenceRules = (): ISequenceRule[] => {
  return [
    LoadingConditionConstant.prototype,
    CargoConstant.prototype,
    FinalSequenceIsInPort.prototype,
  ];
};

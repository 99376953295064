import CircleIcon from '@mui/icons-material/Circle';
import { Toolbar, Typography } from '@mui/material';
import { FixedTimeline } from 'assets/fixed-timeline';
import { useParams } from 'react-router-dom';
import {
  useGetVesselKPIs,
  useGetVoyageComparison,
} from 'routes/environmental-monitor/services/vessel.service';
import {
  CustomBarRectangleItemType,
  EuaChartData,
  GenerateBarItem,
  GenerateChartData,
  MapNAVoyageComparisonToDatapoints,
  MapVoyageComparisonToDatapoints,
} from 'routes/environmental-monitor/view-models/eua-ratings-chart.viewModel';
import { ChartLayerContainer } from 'shared/components/chart-layer-container/chart-layer-container.component';
import { ErrorComponent } from 'shared/components/error/error.component';
import { LoadingComponent } from 'shared/components/loading/loading.component';
import { theme } from 'styles/theme';
import { ColorBarGradientArea } from './eua-ratings-color-bar-gradient-area.component';
import { LeftColorBar } from './eua-ratings-left-color-bar.component';
import { EuaChart } from './eua-ratings-line-chart.component';
import { RightColorBar } from './eua-ratings-right-color-bar.component';
import { vesselPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { TEXT } from 'shared/constants/text';
import { NA_VALUE_INDICATOR } from 'shared/components/datagrid/DataGrid.component';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';

export function EUAChart() {
  const { id } = useParams();
  const startDate = new UTCDate().startOfYear!;
  const endDate = new UTCDate().endOfDay!;
  const dateRange = new DateRange(startDate, endDate);

  const newEuaData = useGetVoyageComparison(id, dateRange);
  const vesselKpis = useGetVesselKPIs(id, dateRange);
  const euaPlanned = parseInt(
    vesselKpis.data?.euaAgainstPlanned.tertiaryValue?.replaceAll(',', '') ?? ''
  );
  const newInOut = newEuaData.data?.legs?.filter((x) => {
    return (
      x.calculations.eua.voyageType.fieldValue?.toString().toUpperCase() ===
      'IN/OUT'
    );
  });
  const newWithin = newEuaData.data?.legs?.filter((x) => {
    return (
      x.calculations.eua.voyageType.fieldValue?.toString().toUpperCase() ===
      'WITHIN'
    );
  });
  const newTotal = newEuaData.data?.legs?.filter((x) => {
    return (
      x.calculations.eua.voyageType.fieldValue?.toString().toUpperCase() !==
      NA_VALUE_INDICATOR
    );
  });
  const newNa = newEuaData.data?.legs?.filter((x) => {
    return (
      x.calculations.eua.voyageType.fieldValue?.toString().toUpperCase() !==
        'IN/OUT' &&
      x.calculations.eua.voyageType.fieldValue?.toString().toUpperCase() !==
        'WITHIN'
    );
  });
  const inOutValues =
    MapVoyageComparisonToDatapoints(startDate, newInOut!) ?? [];
  const withinValues =
    MapVoyageComparisonToDatapoints(startDate, newWithin!) ?? [];
  const naValues = MapNAVoyageComparisonToDatapoints(startDate, newNa!) ?? [];
  const ciiData = MapVoyageComparisonToDatapoints(startDate, newTotal!) ?? [];
  const error = newEuaData.error;
  const loading = newEuaData.loading;
  const hasData =
    newEuaData?.data?.legs !== undefined && newEuaData?.data?.legs?.length > 0;
  const euaPlannedValue = !isNaN(euaPlanned) ? euaPlanned : 0;
  const hasPlannedValue: boolean = euaPlannedValue !== 0 && !isNaN(euaPlanned);
  const chartDataRaw: EuaChartData = {
    rawData: ciiData,
    inOutData: GenerateBarItem(inOutValues, CustomBarRectangleItemType.InOut),
    withinData: GenerateBarItem(
      withinValues,
      CustomBarRectangleItemType.Within
    ),
    naData: GenerateBarItem(naValues, CustomBarRectangleItemType.NA),
    planned: euaPlannedValue,
  };
  let stopsJson = vesselKpis.data?.euaAgainstPlanned.quaternaryValue;
  stopsJson =
    stopsJson === TEXT.ERROR_MESSAGES.EMPTY_DASHES ? undefined : stopsJson;
  const stopsParsed = stopsJson ? JSON.parse(stopsJson) : null;
  const stops = stopsParsed
    ? [stopsParsed.warn ?? 31 / 100, stopsParsed.error ?? 61 / 100]
    : [0.31, 0.61];
  const chartData: EuaChartData = GenerateChartData(chartDataRaw);
  const renderContent = () => {
    if (error || !hasData) {
      return <ErrorComponent error={error} empty={!hasData} />;
    }

    if (loading) {
      return <LoadingComponent height='390px' />;
    }

    return (
      <>
        <Toolbar disableGutters sx={{ paddingLeft: 1.2 }}>
          <Typography
            variant='menuItem'
            sx={{ ml: 8, color: theme.palette.text.primary }}
          >
            Key
          </Typography>
          <CircleIcon
            sx={{
              ml: theme.environmentalMonitor.circleIcons.marginLeft,
              fontSize: theme.environmentalMonitor.circleIcons.fontSize,
              color: theme.environmentalMonitor.colors?.secondaryPurple,
            }}
          />

          <Typography
            variant='body2'
            sx={{ ml: 0.5, color: theme.palette.text.primary }}
          >
            EUA Within EU
          </Typography>
          <CircleIcon
            sx={{
              ml: theme.environmentalMonitor.circleIcons.marginLeft,
              fontSize: theme.environmentalMonitor.circleIcons.fontSize,
              color: theme.environmentalMonitor.colors.cyan,
            }}
          />

          <Typography
            variant='body2'
            sx={{ ml: 0.5, color: theme.palette.text.primary }}
          >
            EUA In/Out EU
          </Typography>
          <CircleIcon
            sx={{
              ml: theme.environmentalMonitor.circleIcons.marginLeft,
              fontSize: theme.environmentalMonitor.circleIcons.fontSize,
              color: theme.environmentalMonitor.colors.green,
            }}
          />

          <Typography
            variant='body2'
            sx={{ ml: 0.5, color: theme.palette.text.primary }}
          >
            YTD EUA
          </Typography>
          {hasPlannedValue && (
            <CircleIcon
              sx={{
                ml: theme.environmentalMonitor.circleIcons.marginLeft,
                fontSize: theme.environmentalMonitor.circleIcons.fontSize,
                color: theme.environmentalMonitor.colors?.magenta,
              }}
            />
          )}
          {hasPlannedValue && (
            <Typography
              variant='body2'
              sx={{ ml: 0.5, color: theme.palette.text.primary }}
            >
              Planned EUA
            </Typography>
          )}
        </Toolbar>
        <ChartLayerContainer height={390}>
          {hasPlannedValue ? (
            <>
              <ColorBarGradientArea
                euaPlannedValue={chartData.planned!}
                ytdTotal={chartData.ytdTotal!}
                stops={stops}
                yMax={chartData.yMax!}
              />
            </>
          ) : (
            <></>
          )}
          {hasPlannedValue ? (
            <>
              <LeftColorBar
                euaPlannedValue={chartData.planned!}
                ytdTotal={chartData.ytdTotal!}
                stops={stops}
                yMax={chartData.yMax!}
              />
            </>
          ) : (
            <></>
          )}
          {hasPlannedValue ? (
            <>
              <RightColorBar
                euaPlannedValue={chartData.planned!}
                ytdTotal={chartData.ytdTotal!}
                stops={stops}
                yMax={chartData.yMax!}
              />
            </>
          ) : (
            <></>
          )}

          <EuaChart chartData={chartData} />
        </ChartLayerContainer>
      </>
    );
  };

  return (
    <CardContainer
      title={vesselPageTitles.vesselEUATrends}
      icon={<FixedTimeline sx={{ margin: '5px 0px 0px 15px' }} />}
    >
      {renderContent()}
    </CardContainer>
  );
}

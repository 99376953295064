import { Link } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'router/router';
import { IFleetUtilizationAndOperationalProfiles } from 'routes/environmental-monitor/models/fleet.model';
import {
  DataGridTable,
  GridRAGColumn,
  nameof,
  GridCustomHeaderClass,
} from 'shared/components/datagrid';

import { theme } from 'styles/theme';

const enum ColumnCSSClass {
  FleetUtilization = 'fleet-utilization',
  OperationalProfile = 'operational-profile',
}

function getColumnsDefinition(navigate: any): GridColDef[] {
  const columns = nameof<IFleetUtilizationAndOperationalProfiles>; // get the properties from the model
  const goToEMVesselPage = (row: any) => {
    navigate(`${ROUTES.environmentalMonitor.path}/vessels/${row.id}`);
  };
  return [
    {
      field: columns('VesselName'),
      headerName: 'Vessel Name',
      flex: 1,
      renderCell: (params: any) => (
        <Link
          onClick={() => goToEMVesselPage(params.row)}
          className='MuiDataGrid-cellContent'
        >
          {params.value}
        </Link>
      ),
    },
    GridRAGColumn({
      field: columns('CII'),
      headerName: 'CII Rating',
      type: 'string',
      headerClassName: GridCustomHeaderClass.BorderRight,
    }),
    {
      field: columns('Laden'),
      headerName: 'Laden (%)',
      type: 'number',
      headerClassName: ColumnCSSClass.FleetUtilization,
    },
    {
      field: columns('Ballast'),
      headerName: 'Ballast (%)',
      type: 'number',
      headerClassName: `${GridCustomHeaderClass.BorderRight} ${ColumnCSSClass.FleetUtilization}`,
    },
    {
      field: columns('AtSea'),
      headerName: 'At Sea (%)',
      type: 'number',
      headerClassName: ColumnCSSClass.OperationalProfile,
    },
    {
      field: columns('InPort'),
      headerName: 'In Port (%)',
      type: 'number',
      headerClassName: ColumnCSSClass.OperationalProfile,
    },
    {
      field: columns('Maneuvering'),
      headerName: 'Maneuvering (%)',
      type: 'number',
      headerClassName: ColumnCSSClass.OperationalProfile,
    },
    {
      field: columns('Idle'),
      headerName: 'Idle (%)',
      type: 'number',
      headerClassName: ColumnCSSClass.OperationalProfile,
    },
  ];
}

export const FleetUtilizationGrid = ({
  data,
}: {
  data: IFleetUtilizationAndOperationalProfiles[];
}) => {
  const navigate = useNavigate();
  const columns = getColumnsDefinition(navigate);
  return (
    <Box sx={{ borderTop: `1px solid ${theme.palette.divider}`, pb: 3 }}>
      <DataGridTable
        name='Fleet KPIs'
        rows={data}
        columns={columns}
      ></DataGridTable>
    </Box>
  );
};

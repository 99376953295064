import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import {
  Box,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  OutlinedInput,
  SelectChangeEvent,
  SxProps,
} from '@mui/material';
import { useState } from 'react';
import { theme } from 'styles/theme';
import {
  fleetCIIHistoricalType,
  fleetCIIHistoricalTypeLabel,
} from 'routes/environmental-monitor/view-models/cii-ratings-chart.viewModel';
const SelectStyles: SxProps = {
  color: theme.palette.text.primary,
  fontWeight: 500,
  pr: 1,
  border: 'none',
  borderRadius: '8px',
  '& .MuiSelect-select': {
    py: 1,
    px: 2,
  },
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
      bgcolor: 'transparent',
    },
    '&:hover fieldset': {
      bgcolor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      bgcolor: 'transparent',
    },
  },
  '& .MuiSvgIcon-root': { color: theme.palette.text.primary, mr: 0 },
  '&:hover': { backgroundColor: 'transparent' },
};

const historicalTypes = [
  {
    value: fleetCIIHistoricalType.YTD,
    label: fleetCIIHistoricalTypeLabel.YTD,
  },
  {
    value: fleetCIIHistoricalType.LAST_YEAR_AND_YTD,
    label: fleetCIIHistoricalTypeLabel.LAST_YEAR_AND_YTD,
  },
];

const findObjFromArr = (
  filterKey: string,
  filterVal: string,
  arr: any[]
): any => {
  let findObj = null;
  arr.forEach((obj: any) => {
    if (obj[filterKey] === filterVal) {
      findObj = obj;
    }
  });
  return findObj;
};

export const CIIHistoricalDataSelection = ({
  handleHistoricaDataChange,
}: {
  handleHistoricaDataChange: any;
}) => {
  const [currentHistoricalType, setcurrentHistoricalType] = useState('ytd');

  const handleChange = (event: SelectChangeEvent) => {
    setcurrentHistoricalType(event.target.value);
    handleHistoricaDataChange(event.target.value);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <Select
          data-testid='select-box'
          sx={{ ...SelectStyles }}
          IconComponent={KeyboardArrowDownSharpIcon}
          input={<OutlinedInput />}
          value={currentHistoricalType}
          onChange={handleChange}
          renderValue={(value: string) => {
            return `Historical Data:  ${
              findObjFromArr('value', value, historicalTypes)?.label ?? ''
            }`;
          }}
        >
          {historicalTypes.map((type, idx) => {
            return (
              <MenuItem
                key={idx}
                value={type.value}
                sx={{ color: theme.palette.text.primary, p: '8px 16px' }}
              >
                {/* <Checkbox sx={{ pl: 0 }} /> */}
                <ListItemText
                  primary={type.label}
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{ color: theme.palette.text.primary, p: '8px 16px' }}
                />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

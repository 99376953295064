import dayjs, { Dayjs, ConfigType } from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import { FieldPolicy } from '@apollo/client';
import { TEXT } from 'shared/constants/text';

dayjs.extend(utcPlugin);
dayjs.extend(timezone);
dayjs.extend(advanced);

dayjs.tz.setDefault('UTC');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dateTypePolicy: FieldPolicy<UTCDate, any> = {
  merge: (_, incoming) => {
    if (!incoming) {
      return incoming;
    } else {
      return new UTCDate(incoming);
    }
  },
};

export class UTCDate {
  private readonly _date: Dayjs | undefined;

  constructor(date?: ConfigType) {
    if (!date) {
      this._date = dayjs.utc();
    } else if (typeof date === 'string') {
      const parsedDate = dayjs.utc(date);
      this._date = parsedDate.isValid() ? parsedDate.utc() : undefined;
    } else if (typeof date === 'number') {
      this._date = dayjs.unix(date).utc();
    } else {
      this._date = dayjs(date).isValid() ? dayjs(date).utc() : undefined;
    }
  }

  get unixTime(): number | undefined {
    return this._date?.unix();
  }

  get dateDayjs(): Dayjs | undefined {
    return this._date;
  }

  get date(): Date | undefined {
    return this._date?.toDate();
  }

  clone(): UTCDate {
    return new UTCDate(this._date);
  }

  get startOfDay(): UTCDate | undefined {
    return new UTCDate(this._date?.startOf('day').toDate());
  }

  get startOfYear(): UTCDate | undefined {
    return new UTCDate(this._date?.startOf('year').toDate());
  }

  get startOfMonth(): UTCDate | undefined {
    return new UTCDate(this._date?.startOf('month').toDate());
  }

  get endOfDay(): UTCDate | undefined {
    return new UTCDate(this._date?.endOf('day').toDate());
  }

  get endOfMonth(): UTCDate | undefined {
    return new UTCDate(this._date?.endOf('month').toDate());
  }

  get endOfYear(): UTCDate | undefined {
    return new UTCDate(this._date?.endOf('year').toDate());
  }

  get year(): number | undefined {
    return this._date?.year();
  }

  toString(): string {
    return this._date?.format() ?? '';
  }

  formatDMY(): string {
    return (
      this._date?.format('DD MMM YYYY') ?? TEXT.ERROR_MESSAGES.EMPTY_DASHES
    );
  }

  static safeFormatDMY(date?: UTCDate): string {
    return date?.formatDMY() ?? TEXT.ERROR_MESSAGES.EMPTY_DASHES;
  }

  formatYMD(): string {
    return this._date?.format('YYYY-MM-DD') ?? '';
  }

  formatM(): string {
    return this._date?.format('MMM') ?? '';
  }

  formatMY(): string {
    return this._date?.format('MMM YYYY') ?? '';
  }

  formatDMYHMS(): string {
    return this._date?.format('DD MMM YYYY HH:mm:ss') ?? '';
  }

  formatDMYHMZ(
    local = false,
    includeSeconds = true,
    letterTimezone = true
  ): string {
    let format = 'DD MMM YYYY HH:mm';

    if (includeSeconds) {
      format += ':ss';
    }

    if (local) {
      format += letterTimezone ? ' z' : ' Z';
    } else {
      format += letterTimezone ? ' UTC' : ' +00:00';
    }

    const formattedDate = local
      ? this._date?.local().format(format) ?? ''
      : this._date?.format(format) ?? '';
    return formattedDate;
  }

  isBeginningOfYear(): boolean {
    const month = this._date?.month();
    return month ? month <= 1 : false;
  }

  isEndOfYear(): boolean {
    const month = this._date?.month();
    return month ? month >= 10 : false;
  }

  isBeginningOrEndOfYear(): boolean {
    return this.isBeginningOfYear() || this.isEndOfYear();
  }

  static create(
    year: number,
    month: number,
    day: number,
    hour?: number,
    minute?: number,
    seconds?: number
  ): UTCDate {
    const date = new Date(year, month, day);
    date.setUTCFullYear(year);
    date.setUTCDate(day);
    date.setUTCMonth(month);
    date.setUTCHours(hour ?? 0);
    date.setUTCMinutes(minute ?? 0);
    date.setUTCSeconds(seconds ?? 0);
    return new UTCDate(date);
  }
}

export class DateRange {
  private readonly _startDate: UTCDate | undefined;
  private readonly _endDate: UTCDate | undefined;

  constructor(startDate?: UTCDate, endDate?: UTCDate, preserveTime?: boolean) {
    startDate = startDate ?? new UTCDate();
    endDate = endDate ?? new UTCDate();

    if (
      startDate.dateDayjs &&
      endDate.dateDayjs &&
      startDate.dateDayjs.isAfter(endDate.date)
    ) {
      throw new Error('startDate must be less than or equal to endDate');
    }
    preserveTime ??= false;

    this._startDate = preserveTime ? startDate?.clone() : startDate?.startOfDay;

    this._endDate = preserveTime ? endDate?.clone() : endDate?.endOfDay;
  }

  get startDate(): UTCDate | undefined {
    return this._startDate;
  }

  get endDate(): UTCDate | undefined {
    return this._endDate;
  }

  formatDMY(): string {
    return `${UTCDate.safeFormatDMY(this._startDate)} - ${UTCDate.safeFormatDMY(
      this._endDate
    )}`;
  }

  get dateRangeAsString(): { startDate: string; endDate: string } | undefined {
    if (this._startDate && this._endDate) {
      const startDateString = this._startDate.dateDayjs?.format();
      const endDateString = this._endDate.dateDayjs?.format();
      return { startDate: startDateString ?? '', endDate: endDateString ?? '' };
    }
    return undefined;
  }

  get getDateRangeStruct():
    | {
        dateRange: { startDateTime: string; endDateTime: string };
      }
    | undefined {
    const startEnd = this.dateRangeAsString;
    if (startEnd) {
      return {
        dateRange: {
          startDateTime: startEnd.startDate,
          endDateTime: startEnd.endDate,
        },
      };
    }
    return undefined;
  }

  static today(query?: string | null) {
    return query ? dayjs(`${query}T00:00:00`).utc() : dayjs().utc();
  }

  static startOftoday() {
    return dayjs().utc().startOf('day');
  }

  static earliestDate() {
    const earliestDate = this.today().year(1980).month(1).date(1);
    return earliestDate;
  }

  static currentYear() {
    return this.today().year();
  }

  static lastYear() {
    return this.today().subtract(1, 'year').year();
  }

  static yearStart(query?: string | null) {
    return this.today(query).startOf('year');
  }

  static readonly getYearToDate = (): [Dayjs, Dayjs] => {
    return [this.yearStart(), this.today().startOf('day')];
  };

  static readonly getLast30Days = (): [Dayjs, Dayjs] => {
    return [this.today().subtract(30, 'day'), this.today().startOf('day')];
  };

  static readonly getLastYear = (): [Dayjs, Dayjs] => {
    const startOfPreviousYear = this.today()
      .subtract(1, 'year')
      .startOf('year');
    const endOfPreviousYear = this.today().subtract(1, 'year').endOf('year');
    return [startOfPreviousYear, endOfPreviousYear];
  };

  static readonly getQ1CurrentYear = (): [Dayjs, Dayjs] => {
    const currentYear = this.today().year();
    const startOfQ1 = this.today().year(currentYear).month(0).date(1);
    const endOfQ1 = this.today()
      .year(currentYear)
      .month(2)
      .date(31)
      .startOf('day');
    return [startOfQ1, endOfQ1];
  };

  static readonly getQ2CurrentYear = (): [Dayjs, Dayjs] => {
    const currentYear = this.today().year();
    const startOfQ2 = this.today().year(currentYear).month(3).date(1);
    const endOfQ2 = this.today()
      .year(currentYear)
      .month(5)
      .date(30)
      .startOf('day');
    return [startOfQ2, endOfQ2];
  };

  static readonly getQ3CurrentYear = (): [Dayjs, Dayjs] => {
    const currentYear = this.today().year();
    const startOfQ3 = this.today().year(currentYear).month(6).date(1);
    const endOfQ3 = this.today()
      .year(currentYear)
      .month(8)
      .date(30)
      .startOf('day');
    return [startOfQ3, endOfQ3];
  };

  static readonly getCurrentQuarter = (): [Dayjs, Dayjs] => {
    const month = this.today().month();
    const startMonthOfQuarter = Math.floor(month / 3) * 3;
    const startOfQ = this.today()
      .clone()
      .month(startMonthOfQuarter)
      .startOf('month');
    return [startOfQ, this.today().startOf('day')];
  };

  static readonly isEndDateAfterToday = (
    endDate: Dayjs,
    today: Dayjs
  ): boolean => {
    return !endDate.isAfter(today);
  };

  static readonly isValidForDateRangePicker = (date: Dayjs | null) => {
    return (
      date !== null &&
      date.isAfter(this.earliestDate()) &&
      !date.isAfter(this.today().startOf('day'))
    );
  };

  static readonly isValidRangeForDateRangePicker = (
    startDay: Dayjs | null,
    endDay: Dayjs | null
  ) => {
    return startDay?.isBefore(endDay) || startDay?.isSame(endDay);
  };

  static readonly getLastAndCurrentYearDateRange = (): DateRange => {
    const startOfPreviousYear = this.today()
      .subtract(1, 'year')
      .startOf('year');
    const endOfPreviousYear = this.today().endOf('year');
    return new DateRange(
      new UTCDate(startOfPreviousYear),
      new UTCDate(endOfPreviousYear)
    );
  };
}

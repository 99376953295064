import { useContext } from 'react';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'router/router';
import { IVessel } from './util/fleet-data-comparison-table.util';
import { TopNavigationContext } from 'shared/models/topNav.context.model';
import { constants } from 'routes/vessel-data-monitor/vessel-data-monitor.route';
import {
  DataGridTable,
  GridRAGColumn,
  nameof,
} from 'shared/components/datagrid/DataGrid.component';
import { GridColDef } from '@mui/x-data-grid-pro';
import { convertKpiStatusToStatus } from 'routes/environmental-monitor/components/fleet-overview/fleet-grid';
import { RAGFilter } from 'shared/components/rag-filter/grid-filter.component';
import { useFilter } from 'shared/components/rag-filter/filter.hook';
import { ApolloError } from '@apollo/client';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { TEXT } from 'shared/constants/text';
import { ExportableSections } from 'shared/utils/valid-sections';

export const FleetDataComparisonTable = ({
  filteredData,
  loading,
  error,
}: {
  filteredData: IVessel[];
  loading: boolean;
  error: ApolloError | undefined;
}) => {
  const { setVesselName } = useContext(TopNavigationContext);
  const navigate = useNavigate();

  function getColumnsDefinition(navigate: any): GridColDef[] {
    const columns = nameof<IVessel>; // get the properties from the model

    const handleClick = (id: string, vesselName: string) => {
      setVesselName(vesselName);
      navigate(ROUTES.vesselDataSummary.path.replace(':id', `${id}`), {
        state: {
          id,
          vesselName,
        },
      });
    };

    const defaultColumns: GridColDef[] = [
      {
        field: columns('vesselName'),
        headerName: 'Vessel Name',
        renderCell: (params: any) => {
          return (
            <>
              <Link
                onClick={() =>
                  handleClick(params.row?.vesselId, params.row.vesselName)
                }
                className='MuiDataGrid-cellContent'
              >
                {params.row.vesselName}
              </Link>
            </>
          );
        },
      },
      GridRAGColumn({
        field: columns('realStatus'),
        headerName: 'Overall Passed Score (%)',
        align: 'center',
        headerAlign: 'center',
      }),

      {
        field: columns('overallPassScore'),
        headerName: 'Passed',
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: any) => {
          return (
            <>
              {params.row.overallPassScore ?? TEXT.ERROR_MESSAGES.EMPTY_DASHES}
            </>
          );
        },
      },
      {
        field: columns('overallFailScore'),
        headerName: 'Flagged',
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: any) => {
          return (
            <>
              {params.row.overallFailScore ?? TEXT.ERROR_MESSAGES.EMPTY_DASHES}
            </>
          );
        },
      },
    ];

    return defaultColumns;
  }

  const translatedData = filteredData.map((x): IVessel => {
    return {
      ...x,
      realStatus: {
        value: x.overallPassScorePercentage ?? '---',
        status: convertKpiStatusToStatus(x.kpiStatus || ''),
      },
    };
  });

  const columns = getColumnsDefinition(navigate);
  const extractKeysForFiltering = ({ realStatus }: IVessel) => ({
    realStatus,
  });
  const filterResult = useFilter<IVessel>(
    translatedData ?? [],
    extractKeysForFiltering
  );

  return (
    <CardContainer title={constants.FC_TABLE_TITLE}>
      <RAGFilter
        activeFilters={filterResult.activeFilters}
        handleToggle={filterResult.handleFilter}
      ></RAGFilter>

      <DataGridTable
        name={ExportableSections['VDM-Fleet Breakdown by Vessels']}
        rows={filterResult.filteredData}
        columns={columns}
        error={error}
        loading={loading}
      ></DataGridTable>
    </CardContainer>
  );
};

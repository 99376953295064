import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DownloadListVessel } from './download-common';
import { useStatementOfFactReportPdf } from 'routes/reports/services/statement-of-fact-report-pdf.service';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';
import { TopNavigationContext } from 'shared/models/topNav.context.model';
import { exportPDF } from 'shared/utils/export-report-pdf.utils';

export interface IDownloadLineItemContext {
  handleGenerateLineItems: (vessels: DownloadListVessel[]) => void;
  selectedVessels: DownloadListVessel[];
  showDownloadModal: boolean;
  triggerStatementOfFactReport: boolean;
  imoNumber: string;
  handleDownload: (key: string, vesselName: string) => void;
  handleClose: () => void;
}

const emptyContext: IDownloadLineItemContext = {
  handleGenerateLineItems: (_vessels) => {
    return;
  },
  selectedVessels: [],
  showDownloadModal: false,
  triggerStatementOfFactReport: false,
  imoNumber: '',
  handleDownload: (_key) => {
    return;
  },
  handleClose: () => {
    return;
  },
};

const DownloadLineItemContext =
  createContext<IDownloadLineItemContext>(emptyContext);

type DownloadLineItemContextProviderProps = {
  children: ReactNode;
};

function DownloadLineItemContextProvider(
  props: DownloadLineItemContextProviderProps
) {
  const { children } = props;
  const [selectedVessels, setSelectedVessels] = useState<DownloadListVessel[]>(
    []
  );
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [triggerStatementOfFactReport, setTriggerStatementOfFactReport] =
    useState<boolean>(false);
  const [imoNumber, setImoNumber] = useState<string>('');
  const [vesselName, setVesselName] = useState<string>('');

  const { dateRange } = useContext(TopNavigationContext);
  const [startDate, endDate] = useMemo(() => {
    const dateRangeString = `${dateRange}`;
    return dateRangeString.split('-');
  }, [dateRange]);

  const { data: statementOfFactData, loading: statementOfFactLoading } =
    useStatementOfFactReportPdf(
      new DateRange(new UTCDate(startDate), new UTCDate(endDate)),
      imoNumber
    );

  useEffect(() => {
    if (statementOfFactData && statementOfFactLoading === false) {
      exportPDF(
        statementOfFactData?.statementOfFactReportPdf?.file?.fileContent,
        `${vesselName}_${startDate}-${endDate}`
      );
    }
  }, [
    endDate,
    startDate,
    statementOfFactData,
    statementOfFactLoading,
    vesselName,
  ]);

  const handleDownload = (key: string, vesselName: string) => {
    setShowDownloadModal(true);
    setTriggerStatementOfFactReport(true);
    setImoNumber(key);
    setVesselName(vesselName);
  };

  const handleGenerateLineItems = (vessels: DownloadListVessel[]) => {
    setSelectedVessels(vessels);
  };

  const handleClose = () => {
    setShowDownloadModal(false);
  };

  const contextValue: IDownloadLineItemContext = {
    handleGenerateLineItems: handleGenerateLineItems,
    selectedVessels: selectedVessels,
    showDownloadModal: showDownloadModal,
    triggerStatementOfFactReport: triggerStatementOfFactReport,
    imoNumber: imoNumber,
    handleDownload: handleDownload,
    handleClose: handleClose,
  };

  return (
    <DownloadLineItemContext.Provider value={contextValue}>
      {children}
    </DownloadLineItemContext.Provider>
  );
}

export { DownloadLineItemContext, DownloadLineItemContextProvider };

import { theme } from 'styles/theme';
import { WORKSHEET } from '../../worksheet';
import * as ExcelJs from 'exceljs';

import { GridApiPro } from '@mui/x-data-grid-pro';
import { SectionName } from '../../valid-sections';
import {
  SetColumnWidths,
  checkCards,
  gridExcelExport,
  setCellBackgroundColor,
} from '.';
import { StatusKpiCardProps } from 'shared/components/status-kpi-card/status-kpi-card.component';

export const ExportToExcel = ({
  sectionName,
  cardData,
  filteredData,
  introductoryRowsData,
}: {
  sectionName: SectionName;
  cardData?: StatusKpiCardProps[];
  filteredData: any;
  introductoryRowsData?: string[][];
}): ExcelJs.Workbook => {
  try {
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet();

    // add introductoryRows is worksheet
    AddIntroductoryRow(worksheet, introductoryRowsData);

    // KPI Card
    if (cardData) {
      checkCards(worksheet, cardData, sectionName);
    }

    // DataGrid
    const grids = Object.values(filteredData).filter(
      (x: any) => x.sectionId === sectionName
    );
    grids.forEach((grid: any) => {
      const gridRef: GridApiPro = grid.apiRef.current;
      if (gridRef) {
        // gridRef is valid for Grids
        gridExcelExport(worksheet, gridRef);
      }
    });

    // set columns width
    SetColumnWidths(worksheet, WORKSHEET.EMPTY_ROW);

    return workbook;
  } catch (err: any) {
    console.log('An error occurred: ' + err.message);
    return new ExcelJs.Workbook();
  }
};

export const ExportExcelFile = (
  workbook: ExcelJs.Workbook,
  filename: string
) => {
  return workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};

const AddIntroductoryRow = (
  worksheet: ExcelJs.Worksheet,
  data: string[][] | undefined
) => {
  data?.forEach(([label, value]) => {
    const row = worksheet.addRow([label, value]);
    row.eachCell((cell, colNumber) => {
      if (colNumber === 1) setCellBackgroundColor(cell, theme.excelColour.grey);
    });
  });
  worksheet.addRow(WORKSHEET.EMPTY_ROW);
};

import { SxProps, alpha, styled } from '@mui/material/styles';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';
import { theme } from 'styles/theme';
import { typography } from 'styles/typography';

const ODD_OPACITY = 0.2;
const borderColor = `1px solid ${theme.palette.divider}`;

export enum GridCustomHeaderClass {
  BorderLeft = 'border-left',
  BorderRight = 'border-right',
  PreLine = 'pre-line',
  NoGutters = 'no-gutters',
}

const themeProperties: SxProps = {
  border: 0,

  '& .MuiDataGrid-row, & .MuiDataGrid-cell': {
    border: 0,
  },

  '& .MuiDataGrid-cellContent': {
    pointerEvents: 'none',
  },

  '& .whiteSpaceAndBreakWords .MuiDataGrid-cellContent': {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },

  [`& .${GridCustomHeaderClass.PreLine}.MuiDataGrid-cell`]: {
    whiteSpace: 'pre-line !important',
  },

  '& span.MuiDataGrid-cellContent.pointer, a.MuiDataGrid-cellContent': {
    cursor: 'pointer',
    pointerEvents: 'initial',
  },

  '& .MuiChip-root': {
    cursor: 'unset',
  },

  [`& .${GridCustomHeaderClass.NoGutters}.MuiDataGrid-columnHeader,
  & .${GridCustomHeaderClass.NoGutters}.MuiDataGrid-cell`]: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },

  // left margin
  '& .MuiDataGrid-columnHeader:first-of-type, .MuiDataGrid-cell:first-of-type':
    {
      paddingLeft: '16px',
    },

  '& .MuiDataGrid-columnHeader--alignLeft': {
    paddingLeft: '10px !important',
  },

  '& .MuiDataGrid-columnHeader--alignRight': {
    paddingRight: '10px !important',
  },

  '& .MuiDataGrid-detailPanel': {
    backgroundColor: 'rgb(29, 38, 47) !important',
  },

  '& .rounded.MuiDataGrid-row': {
    backgroundColor: 'rgba(255, 255, 255, 0.08) !important',
    borderRadius: 8,
  },

  // right margin
  '& .MuiDataGrid-columnHeader:last-of-type, .MuiDataGrid-cell:last-of-type': {
    paddingRight: '16px',
  },

  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },

  borderBottom: borderColor,

  '& .MuiDataGrid-footerContainer': {
    borderBottom: 0,
    borderTop: borderColor,
  },

  [`& .${gridClasses.columnHeaderTitle}`]: {
    width: '100%',
  },

  [`& .whiteSpaceAndBreakWords .${gridClasses.columnHeaderTitle}`]: {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    textAlign: 'right',
  },

  [`& .${gridClasses.columnHeaderTitle} p`]: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 'fill-available',
  },

  [`& .${gridClasses.columnHeader}`]: {
    fontSize: typography.fontSize['3'],
    lineHeight: typography.lineHeights['7'],
    padding: '2px',
  },

  [`& .${gridClasses.columnHeader}.${GridCustomHeaderClass.BorderLeft}`]: {
    borderLeft: borderColor,
  },

  [`& .${gridClasses.columnHeader}.${GridCustomHeaderClass.BorderRight}`]: {
    borderRight: borderColor,
  },

  [`& .${gridClasses.columnHeaders}`]: {
    borderBottom: 'unset',
  },

  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)),rgba(255, 255, 255, 0.08) !important',
    },
  },

  [`& .${gridClasses.row}.even`]: {
    border: 'none',
    borderBottom: borderColor,
    backgroundColor: 'rgba(255, 255, 255, 0.08)',

    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
};

export const StripedDataGrid = styled(DataGridPro)(() => themeProperties);
export const columnHeaderProps: any =
  themeProperties[`& .${gridClasses.columnHeader}`];

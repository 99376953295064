import { ServiceResult } from 'shared/models/serviceResult.model';
import { FeatureFlagMapper } from 'shared/mappers/featureFlag.mapper';
import { useFeatureFlagData } from '../repositories/featureFlag.repository';
import { FeatureFlagDomain } from 'shared/models/featureFlag.model';

export const useGetFeatureFlag = (
  flagName: string
): ServiceResult<FeatureFlagDomain> => {
  const response = useFeatureFlagData(flagName);

  const featureFlag = response?.data?.featureFlag;
  const loading = response?.loading ?? false;
  const error = response?.error;

  return {
    data: FeatureFlagMapper.toDomain(featureFlag),
    loading,
    error,
  };
};

import * as ExcelJs from 'exceljs';

import { GridApiPro } from '@mui/x-data-grid-pro';
import { getExcelData } from 'shared/components/datagrid/DataGrid.component';

import { WORKSHEET } from '../../../worksheet';
import { AddHeaderRows, SetColumnWidths, StyleTableGridValues } from '..';

export const AddTableRows = (data: any[], worksheet: ExcelJs.Worksheet) =>
  data.forEach((x) => {
    worksheet.addRow(Object.values(x));
  });

export const gridExcelExport = (
  worksheet: ExcelJs.Worksheet,
  gridRef: GridApiPro,
  headingRow = 4
) => {
  const excelData = getExcelData(gridRef);
  const columnHeaders = excelData.headers;
  const colorColumnIndexes = excelData.colorColumnIndexes ?? [];

  // Add heading row
  AddHeaderRows(columnHeaders, worksheet);

  // Add  Vessel Data Summary table
  AddTableRows(excelData.data, worksheet);

  // Add color to cells
  StyleTableGridValues(
    worksheet,
    headingRow,
    colorColumnIndexes?.map((x) => x.toString())
  );

  // set width to columns
  SetColumnWidths(worksheet, WORKSHEET.EMPTY_ROW);

  // Add empty rows
  worksheet.addRow(WORKSHEET.EMPTY_ROW);
  worksheet.addRow(WORKSHEET.EMPTY_ROW);
};

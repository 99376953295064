import { Box } from '@mui/system';
import { FleetUtilizationCharts } from './charts';
import { FleetUtilizationGrid } from './grid';
import { theme } from 'styles/theme';
import { Typography } from '@mui/material';
import { useGetFleetUtilization } from 'routes/environmental-monitor/services/fleet.service';
import { ErrorComponent } from 'shared/components/error/error.component';
import { LoadingComponent } from 'shared/components/loading/loading.component';
import { fleetPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';

export const FleetUtilizationContainer = ({ dateRange, vesselImos }: any) => {
  const { data, loading, error, empty } = useGetFleetUtilization(
    dateRange,
    vesselImos
  );

  const charts = (
    <>
      <FleetUtilizationCharts
        data={data?.fleetUtilization}
      ></FleetUtilizationCharts>
      <FleetUtilizationGrid
        data={data?.vesselUtilization ?? []}
      ></FleetUtilizationGrid>
    </>
  );

  const errorEmptyComponent = <ErrorComponent error={error} empty={empty} />;
  const contentToDisplay = loading ? (
    <LoadingComponent height='310px' />
  ) : empty || error ? (
    errorEmptyComponent
  ) : (
    charts
  );

  return (
    <Box
      component='div'
      sx={{
        pt: 5,
        marginTop: 3,
        backgroundColor: theme.background.component,
      }}
    >
      <Typography
        variant='h3'
        paragraph
        sx={{
          mb: 3,
          display: 'flex',
          justifyContent: 'center',
          color: theme.palette.text.primary,
        }}
      >
        {fleetPageTitles.utilizationAndOperationalProfilesBreakdown}
      </Typography>
      {contentToDisplay}
    </Box>
  );
};

import { Label, ReferenceArea } from 'recharts';
import { UTCDate } from 'shared/utils/date-utc-helper';
import { theme } from 'styles/theme';

export function TopBarDates({
  props,
}: {
  props: {
    currentTime: number;
    min: number;
    max: number;
    dataMax: number;
    chartMax: number;
    leftLabel?: string;
  };
}) {
  return (
    <>
      <ReferenceArea
        ifOverflow='extendDomain'
        x1={props.min}
        x2={props.currentTime}
        y1={props.dataMax}
        y2={props.chartMax}
        stroke={theme.colors?.blueGray}
        strokeOpacity={0.6}
        fill={theme.colors?.blueGray}
        fillOpacity={1}
      >
        {!new UTCDate().isBeginningOfYear() && (
          <Label
            value={props.leftLabel ?? 'Year to Date'}
            position='center'
            fill={theme.palette.text.primary}
            fontSize={14}
          />
        )}
      </ReferenceArea>
      <ReferenceArea
        ifOverflow='visible'
        x1={props.currentTime}
        x2={props.max}
        y1={props.dataMax}
        y2={props.chartMax}
        stroke={theme.colors.white}
        strokeOpacity={1}
        fill={theme.colors.white}
        fillOpacity={1}
      >
        {!new UTCDate().isEndOfYear() && (
          <Label
            value='End of Year Projections'
            position='center'
            fill='black'
            fontSize={14}
          />
        )}
      </ReferenceArea>
    </>
  );
}

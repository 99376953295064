import {
  INavBarContext,
  NavBarContext,
} from 'shared/models/navBar.context.model';
import { useCallback, useState } from 'react';
import { Dayjs } from 'dayjs';
import { DownloadListVessel } from 'routes/reports/components/generateReport/download-common';
import { DateRange } from 'shared/utils/date-utc-helper';

export const NavBarWrapper = (props: {
  children: JSX.Element;
}): JSX.Element => {
  const setImoNumbers = useCallback((imos: string[]) => {
    setContext((prevContext) => ({ ...prevContext, imoNumbers: imos }));
  }, []);

  const setDateRange = (start: Dayjs, end: Dayjs) => {
    setContext((prevContext) => ({
      ...prevContext,
      dateRange: { startDate: start, endDate: end },
    }));
  };

  const setUTCDateRange = (dateRange: DateRange) => {
    setContext((prevContext) => ({
      ...prevContext,
      utcDateRange: dateRange,
    }));
  };

  const setVessels = useCallback((vessels: DownloadListVessel[]) => {
    setContext((prevContext) => ({ ...prevContext, vessels: vessels }));
  }, []);

  const alertCount = 0;
  const setAlertCount = (count: number) => {
    setContext((prevContext) => ({ ...prevContext, alertCount: count }));
  };

  const navBarContext: INavBarContext = {
    imoNumbers: [],
    setImoNumbers,
    vessels: [],
    dateRange: {
      startDate: null,
      endDate: null,
    },
    utcDateRange: null,
    setDateRange,
    setUTCDateRange,
    setVessels,
    alertCount,
    setAlertCount,
  };

  const [context, setContext] = useState(navBarContext);

  return (
    <NavBarContext.Provider value={context}>
      {props.children}
    </NavBarContext.Provider>
  );
};

import { Box } from '@mui/material';
import { AERChart } from './aer/aer-chart.component';
import { CIIRatingsChart } from './cii-ratings/cii-ratings-chart.component';
import { EUAChart } from './eua/eua-ratings-chart.component';

export const VesselEmissionsOutlook = () => {
  return (
    <Box display='flex' flexDirection='column'>
      <CIIRatingsChart />
      <AERChart />
      <EUAChart />
    </Box>
  );
};

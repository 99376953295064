import {
  Box,
  Button,
  IconProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography,
} from '@mui/material';

import {
  downloadButtonStyle,
  downloadItemBoxStyle,
  downloadListStyle,
  svgIconStyle,
} from './download-common';
import { ReactElement, useContext } from 'react';
import { TopNavigationContext } from 'shared/models/topNav.context.model';
import { DownloadLineItemContext } from './download-line-item-context';

type DownloadLineItemListProps = {
  downloadButtonText: string;
  icon: ReactElement<IconProps>;
};

export const DownloadLineItemList = (props: DownloadLineItemListProps) => {
  const { downloadButtonText, icon } = props;

  const { dateRange } = useContext(TopNavigationContext);
  const { selectedVessels, handleDownload } = useContext(
    DownloadLineItemContext
  );

  return (
    <div>
      <List sx={downloadListStyle}>
        {selectedVessels.map((vessel) => {
          return (
            <ListItem sx={{ pl: 0, py: 1.5 }} key={vessel.imoNumber}>
              <ListItemText
                id={vessel.imoNumber}
                primary={
                  <Typography fontWeight={'bold'}>{vessel.name}</Typography>
                }
                secondary={<Typography>{dateRange}</Typography>}
              />
              <Box sx={downloadItemBoxStyle}>
                <ListItemIcon sx={{ pl: 5, m: 0 }}>
                  <SvgIcon sx={svgIconStyle} viewBox='0 0 100 100'>
                    {icon}
                  </SvgIcon>
                </ListItemIcon>
                <Button
                  variant='outlined'
                  sx={downloadButtonStyle}
                  aria-label='download'
                  onClick={() => handleDownload(vessel.imoNumber, vessel.name)}
                >
                  {downloadButtonText}
                </Button>
              </Box>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

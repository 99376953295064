import { SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { MDFTooltip } from 'shared/components/tooltip/tooltip.component';
import {
  getStatus,
  getStatusValues,
  StatusKpiCardValues,
} from './status-kpi-card.viewModel';
import { KPICardFooter } from './kpi-card-footer/kpi-card-footer';
import { typography } from 'styles/typography';
import { FixedTimeline } from 'assets/fixed-timeline';
import { TEXT } from 'shared/constants/text';
import { Status } from 'shared/models/status.type';
import { Status as RAGStatus } from 'routes/environmental-monitor/models/gql/vessel-kpi-gql.model';

export type KpiData = {
  primaryValue: string;
  unit?: string;
  secondaryValue?: string;
  tertiaryValue?: string;
  quaternaryValue?: string;
  status: Status;
};

export type customFooterType = {
  title: string;
  subTitle: string;
  titleUnits?: string;
  subTitleUnits?: string;
};
export type footerType =
  | 'required'
  | 'target'
  | 'planned'
  | 'vessel-fleet'
  | 'eua-planned'
  | 'default-none'
  | undefined
  | customFooterType;

export interface StatusKpiCardProps {
  title: string;
  kpiData: KpiData | undefined;
  units?: string;
  unitSize?: string | undefined;
  showTooltip?: boolean;
  tooltipText?: string;
  footer?: boolean;
  footerType?: footerType;
  footerUnits?: string;
  footerSize?: string;
  fixedTimeline?: boolean;
  value?: string;
  sectionVisibility?: {
    title?: boolean;
    primaryValue?: boolean;
    footer?: boolean;
  };
  customGetStatusValues?: (status: string) => StatusKpiCardValues;
}

export const StatusKpiCard = (props: StatusKpiCardProps) => {
  const theme = useTheme();
  const { fontFamilies, fontWeights, lineHeights, fontSize, letterSpacing } =
    typography;

  const cardBackground = theme.background.component;

  const kpiStatus = getStatus(props.footerType, props.kpiData?.status);

  const { color: statusBarBackground, icon: statusIcon } =
    props.customGetStatusValues
      ? props.customGetStatusValues(props.kpiData?.status ?? RAGStatus.success)
      : getStatusValues(kpiStatus);

  const statusBarStyle: SxProps = {
    backgroundColor: statusBarBackground,
    height: 8,
    margin: 0,
  };

  const dividerStyle: SxProps = {
    backgroundColor: theme.colors.divider,
    mt: 2,
  };

  const cardStyle: SxProps = {
    borderRadius: '12px',
    backgroundColor: cardBackground,
    minWidth: '245px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  };

  const topBoxStyle: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: '0px',
    height: lineHeights['3'],
  };

  const boxStyle: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 2,
    justifyContent: 'space-between',
  };

  const kpiTitleStyle: SxProps = {
    fontFamily: fontFamilies.roboto,
    fontSize: fontSize['12'],
    fontWeight: fontWeights['roboto-2'],
    letterSpacing: letterSpacing['4'],
    lineHeight: lineHeights['5'],
    alignSelf: 'flex-start',
  };

  const kpiPrimaryContentStyle: SxProps = {
    alignSelf: 'flex-end',
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights['roboto-2'],
    lineHeight: '42px',
    fontSize: fontSize['10'],
    letterSpacing:
      props.kpiData?.primaryValue === TEXT.ERROR_MESSAGES.EMPTY_DASHES
        ? letterSpacing['2']
        : letterSpacing['0'],
  };

  const kpiPrimaryContentAdjacentTextStyle: SxProps = {
    alignSelf: 'flex-end',
    pl: 1,
    pb: '5.5px',
    fontFamily: fontFamilies.roboto,
    fontWeight: fontWeights['roboto-2'],
    lineHeight: lineHeights['5'],
    fontSize: props.unitSize ?? fontSize['12'],
    letterSpacing: letterSpacing['4'],
  };

  const unit =
    props.kpiData?.primaryValue === TEXT.ERROR_MESSAGES.EMPTY_DASHES
      ? ''
      : props.kpiData?.unit ?? '';

  const secondRowVisible =
    props.sectionVisibility?.primaryValue !== false ? 'flex' : 'none';

  return (
    <Card sx={cardStyle}>
      <Divider sx={statusBarStyle} />
      <CardContent sx={{ m: '24px', alignContent: 'center', height: '100%' }}>
        <Box sx={topBoxStyle}>
          <Typography sx={kpiTitleStyle}>{props.title}</Typography>
          {props?.showTooltip && props?.tooltipText && (
            <MDFTooltip tooltipText={props?.tooltipText} />
          )}

          {props.fixedTimeline && (
            <Box sx={{ alignSelf: 'flex-end' }}>
              <FixedTimeline fillOpacity={0.5} />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            ...boxStyle,
            paddingTop: 2,
            alignItems: 'center',
            display: secondRowVisible,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Typography sx={kpiPrimaryContentStyle}>
              {props.kpiData?.primaryValue + unit}
            </Typography>
            {props.units &&
              props.units !== TEXT.ERROR_MESSAGES.EMPTY_DASHES && (
                <Typography sx={kpiPrimaryContentAdjacentTextStyle}>
                  {props.units &&
                  props.kpiData?.primaryValue !==
                    TEXT.ERROR_MESSAGES.EMPTY_DASHES
                    ? props.units
                    : ''}
                </Typography>
              )}
          </Box>
          <Box>
            {statusIcon && (
              <img src={statusIcon} alt='status' style={{ display: 'block' }} />
            )}
          </Box>
        </Box>
        {props.footer && (
          <>
            <Divider sx={dividerStyle} />
            <Box sx={{ ...boxStyle }}>
              <KPICardFooter
                footerSize={props.footerSize}
                footerType={props.footerType}
                footerUnits={props.footerUnits}
                kpiData={props.kpiData}
              />
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

import {
  AerKpi,
  CiiKpi,
  EeoiKpi,
  EuaAgainstFleetKpi,
  EuaAgainstPlannedKpi,
  VesselKpis,
} from '_gql/graphql';
import { formatFloat, formatNumber } from 'shared/utils/float-utils';
import { toStatusEnum } from 'shared/utils/status-utils';
import { VesselKpiDomain } from '../models/vessel-kpi.model';
import { TEXT } from 'shared/constants/text';
import { NA_VALUE_INDICATOR } from 'shared/components/datagrid/DataGrid.component';
import { KpiData } from 'shared/components/status-kpi-card/status-kpi-card.component';

const formatWithUnit = (value: any, unit: string) => {
  const result = formatNumber(value);
  if (result === TEXT.ERROR_MESSAGES.EMPTY_DASHES) return result;
  return result + unit;
};

export const formatWithPercentage = (value: any) => formatWithUnit(value, '%');

export class VesselKPIDataMapper {
  static fromDTO(dto: VesselKpis | undefined): VesselKpiDomain {
    return {
      aer: VesselKPIDataMapper.toAerDomain(dto?.aer ?? undefined),
      ciiRating: VesselKPIDataMapper.toCiiDomain(dto?.cii ?? undefined),
      ciiYearToDate: VesselKPIDataMapper.toCiiDomain(dto?.ciiYtd ?? undefined),
      ciiForecast: VesselKPIDataMapper.toCiiDomain(dto?.ciiEoy ?? undefined),
      eeoi: VesselKPIDataMapper.toEeoiDomain(dto?.eeoi ?? undefined),
      euaAgainstFleet: VesselKPIDataMapper.toEuaAgainstFleetDomain(
        dto?.euaAgainstFleet ?? undefined
      ),
      euaAgainstPlanned: VesselKPIDataMapper.toEuaAgainstPlannedDomain(
        dto?.euaAgainstPlanned ?? undefined
      ),
    };
  }

  private static toAerDomain(dto: AerKpi | undefined): KpiData {
    return {
      primaryValue: formatWithPercentage(dto?.deviation),
      secondaryValue: formatFloat(dto?.attained ?? 0, false),
      tertiaryValue: formatFloat(dto?.required ?? 0, false),
      quaternaryValue: TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      status: toStatusEnum(dto?.status),
    };
  }

  private static toCiiDomain(dto: CiiKpi | undefined): KpiData {
    let quaternaryValue = formatWithPercentage(dto?.deviation);
    let primaryValue = dto?.ciiRating ?? NA_VALUE_INDICATOR;
    if (primaryValue === NA_VALUE_INDICATOR || primaryValue === '') {
      primaryValue = TEXT.ERROR_MESSAGES.EMPTY_DASHES;
      quaternaryValue = TEXT.ERROR_MESSAGES.EMPTY_DASHES;
    }
    return {
      primaryValue: primaryValue,
      secondaryValue: formatFloat(dto?.attained ?? 0, false),
      tertiaryValue: formatFloat(dto?.required ?? 0, false),
      quaternaryValue: quaternaryValue,
      status: toStatusEnum(dto?.status),
    };
  }

  private static toEeoiDomain(dto: EeoiKpi | undefined): KpiData {
    return {
      primaryValue: formatWithPercentage(dto?.deviation),
      secondaryValue: formatFloat(dto?.actual ?? 0, false),
      tertiaryValue: formatFloat(dto?.target ?? 0, false),
      quaternaryValue: TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      status: toStatusEnum(dto?.status),
    };
  }

  private static toEuaAgainstFleetDomain(
    dto: EuaAgainstFleetKpi | undefined
  ): KpiData {
    return {
      primaryValue: `${
        dto?.value === -1
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : dto?.value
          ? dto?.value?.toFixed() + '%'
          : NA_VALUE_INDICATOR
      }`,
      secondaryValue:
        dto?.vessel === -1
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : dto?.vessel
          ? dto?.vessel?.toFixed()
          : NA_VALUE_INDICATOR,
      tertiaryValue:
        dto?.fleet === -1
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : dto?.fleet
          ? dto?.fleet?.toFixed()
          : NA_VALUE_INDICATOR,
      quaternaryValue: '',
      status: toStatusEnum(dto?.status),
    };
  }

  private static toEuaAgainstPlannedDomain(
    dto: EuaAgainstPlannedKpi | undefined
  ): KpiData {
    return {
      primaryValue:
        dto?.value === -1
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : dto?.value
          ? dto?.value?.toFixed()?.toString() + '%'
          : NA_VALUE_INDICATOR,
      secondaryValue:
        dto?.value === -1
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : dto?.vesselEuaLiability
          ? dto?.vesselEuaLiability.toFixed()
          : NA_VALUE_INDICATOR,
      tertiaryValue: formatNumber(dto?.vesselEuaPlannedValue), //this is the eua planned value for the vessel
      quaternaryValue:
        JSON.stringify(dto?.thresholds) ?? TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      status: toStatusEnum(dto?.status),
    };
  }
}

import { createContext } from 'react';

export interface ExcelExportContextType {
  cardData?: any;
  filteredData?: any;
  tilesData?: any;
  additionalIntroductoryRowsData?: { [key: string]: string[][] };
  setCardData: Function;
  setFilteredData: Function;
  setTilesData: Function;
  setAdditionalIntroductoryRowsData?: (
    sectionId: string,
    data: string[][]
  ) => void;
}

export const defaultContext: ExcelExportContextType = {
  cardData: [],
  filteredData: [],
  tilesData: [],
  additionalIntroductoryRowsData: {},
  setCardData: () => {},
  setFilteredData: () => {},
  setTilesData: () => {},
  setAdditionalIntroductoryRowsData: () => {},
};

export const ExcelExportContext =
  createContext<ExcelExportContextType>(defaultContext);

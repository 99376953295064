import { gql } from '@apollo/client';

export const CORE_VESSEL_FIELDS = gql`
  fragment VesselProperties on Vessel {
    id
    name
    imoNumber
    deadWeight
    grossTonnage
    portOfRegistration
  }
`;

export const GET_VESSEL_DETAIL = gql`
  ${CORE_VESSEL_FIELDS}
  query GetVesselDetail($id: String!) {
    vessel(id: $id) {
      ...VesselProperties
    }
  }
`;

export const GET_VESSEL_KPI_DATA = gql`
  query GetVesselKPIDataById(
    $id: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    vessel(
      id: $id
      dateRange: { startDateTime: $startDate, endDateTime: $endDate }
    ) {
      id
      kpis(startDate: $startDate, endDate: $endDate) {
        aer {
          required
          attained
          deviation
          status
        }
        cii {
          required
          ciiRating
          attained
          deviation
          status
        }
        ciiYtd {
          required
          ciiRating
          attained
          deviation
          status
        }
        ciiEoy {
          required
          ciiRating
          attained
          deviation
          status
        }
        eeoi {
          target
          actual
          deviation
          status
        }
        euaAgainstFleet {
          vessel
          value
          fleet
          status
        }
        euaAgainstPlanned {
          value
          vesselEuaLiability
          vesselEuaPlannedValue
          status
          thresholds {
            warn
            error
          }
        }
      }
    }
  }
`;

export const GET_AER_CHART_DATA_BY_VESSEL_ID = gql`
  query GetAerChartDataByVesselId(
    $id: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    vessel(id: $id) {
      id
      calculations(endDate: $endDate, startDate: $startDate) {
        id
        aer {
          required
          data {
            date
            deviation
            value
          }
        }
      }
    }
  }
`;

export const GET_VESSEL_VOYAGE_COMPARISON = gql`
  query GetVesselVoyageComparison(
    $id: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    vessel(id: $id) {
      id
      name
      environmentalMonitor(endDate: $endDate, startDate: $startDate) {
        vesselName
        voyageComparison {
          departurePort
          arrivalPort
          leg
          departureDate
          arrivalDate
          distance
          speed
          fuelConsumed
          co2Emissions
          calculations {
            aer {
              attained
              delta
              deviation
              required
              status
            }
            cii {
              ciiRating
              delta
              deviation
              required
              status
            }
            ciiPercentage {
              deviation
              status
            }
            eeoi {
              actual
              delta
              deviation
              status
              target
            }
            eua {
              euCo2
              liability
              voyageType
              status
              euaByYear {
                year
                liability
                euCo2
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_VESSEL_VOYAGE_COMPARISON_LAST_PORT = gql`
  query GetVesselVoyageComparisonLastPort(
    $id: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    vessel(id: $id) {
      id
      name
      environmentalMonitor(endDate: $endDate, startDate: $startDate) {
        vesselName
        voyageComparison {
          departurePort
          departurePortCode
          leg
        }
      }
    }
  }
`;

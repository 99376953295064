import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useGetVoyageComparison } from 'routes/environmental-monitor/services/vessel.service';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { vesselPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import {
  DataGridTable,
  GridColumn,
  GridColumnDef,
  GridCustomHeaderClass,
  GridRAGColumn,
  NA_VALUE_INDICATOR,
  nameof,
} from 'shared/components/datagrid';
import { IVoyageComparisonLegDomain } from 'routes/environmental-monitor/models/voyage-comparison.model';
import { TEXT } from 'shared/constants/text';
import { RAGFilter } from 'shared/components/rag-filter/grid-filter.component';
import { useFilter } from 'shared/components/rag-filter/filter.hook';
import { IRagFilterData } from 'routes/dashboard/models/fleet-kpis.model';
import { euaLiabilitiesTooltipHeader } from '../../tooltip/eua-tooltip.component';
import { formatNumber, stringToNumber } from 'shared/utils/float-utils';
import { Tooltip } from '@mui/material';
import { GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { UTCDate } from 'shared/utils/date-utc-helper';
import { Box } from '@mui/system';
import { useGetFeatureFlag } from 'shared/services/featureFlag.service';

interface IVoyageComparisonLegDomainWithStatus
  extends IVoyageComparisonLegDomain {
  realCalculations: {
    cii?: IRagFilterData;
    ciiDeviation?: IRagFilterData;
    eeoiDeviation?: IRagFilterData;
    aerDeviation?: IRagFilterData;
  };
}

const SMALL_CELL_WIDTH = 135;
const BIG_CELL_WIDTH = 180;

function getColumnsDefinition(
  crossingYearsFeatureFlagEnabled: any
): GridColumnDef[] {
  const columns = nameof<IVoyageComparisonLegDomainWithStatus>; // get the properties from the model

  return [
    GridColumn({
      field: columns('legNumber.displayValue'),
      headerName: 'Leg #',
      valueField: columns('legNumber.fieldValue'),
      minWidth: 90,
    }),
    GridRAGColumn({
      field: columns('realCalculations.cii'),
      headerName: 'CII \nRating',
      type: 'string',
      headerClassName: GridCustomHeaderClass.BorderLeft,
      minWidth: SMALL_CELL_WIDTH,
    }),
    GridColumn({
      field: columns('calculations.cii.delta.displayValue'),
      headerName: 'CII \nΔ',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      valueField: columns('calculations.cii.delta.fieldValue'),
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    GridRAGColumn({
      field: columns('calculations.ciiPercentage.deviation.fieldValue'),
      headerName: 'CII \n%',
      type: 'number',
      valueField: columns('calculations.ciiPercentage.deviation.fieldValue'),
      statusField: columns('calculations.ciiPercentage.status'),
      headerClassName: GridCustomHeaderClass.BorderRight,
      minWidth: SMALL_CELL_WIDTH,
    }),
    GridColumn({
      field: columns('calculations.eeoi.value.displayValue'),
      headerName: `EEOI \n(${TEXT.FORMULAS.EEOI})`,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueField: columns('calculations.eeoi.value.fieldValue'),
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    GridColumn({
      field: columns('calculations.eeoi.delta.displayValue'),
      headerName: 'EEOI \nΔ',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueField: columns('calculations.eeoi.delta.fieldValue'),
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    GridRAGColumn({
      field: columns('realCalculations.eeoiDeviation'),
      headerName: 'EEOI \n%',
      type: 'number',
      valueField: columns('calculations.eeoi.deviation.fieldValue'),
      headerClassName: GridCustomHeaderClass.BorderRight,
      minWidth: SMALL_CELL_WIDTH,
    }),
    GridColumn({
      field: columns('calculations.aer.value.displayValue'),
      headerName: `AER \n(${TEXT.FORMULAS.AER})`,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueField: columns('calculations.aer.value.fieldValue'),
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    GridColumn({
      field: columns('calculations.aer.delta.displayValue'),
      headerName: 'AER \nΔ',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueField: columns('calculations.aer.delta.fieldValue'),
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    GridRAGColumn({
      field: columns('calculations.aer.deviation.fieldValue'),
      headerName: 'AER \n%',
      type: 'number',
      valueField: columns('calculations.aer.deviation.fieldValue'),
      statusField: columns('calculations.aer.status'),
      headerClassName: GridCustomHeaderClass.BorderRight,
      minWidth: SMALL_CELL_WIDTH,
    }),
    {
      field: columns('departurePort.displayValue'),
      headerName: 'Departure \nPort',
      type: 'string',
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) =>
        params.row.departurePort.displayValue ??
        TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      minWidth: BIG_CELL_WIDTH,
    },
    {
      field: columns('departureDate.fieldValue'),
      headerName: 'Departure \nDate',
      type: 'date',
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) => params.row.departureDate.fieldValue ?? '',
      valueFormatter: (params) =>
        params?.value
          ? new UTCDate(params?.value)?.formatDMY()
          : TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      headerClassName: GridCustomHeaderClass.BorderRight,
      minWidth: SMALL_CELL_WIDTH,
    },
    {
      field: columns('arrivalPort.displayValue'),
      headerName: 'Arrival Port',
      type: 'string',
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) =>
        params.row.arrivalPort.displayValue ?? TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      minWidth: BIG_CELL_WIDTH,
    },
    {
      field: columns('arrivalDate.fieldValue'),
      headerName: 'Arrival \nDate',
      type: 'date',
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) => params.row.arrivalDate.fieldValue ?? '',
      valueFormatter: (params) =>
        params?.value
          ? new UTCDate(params?.value)?.formatDMY()
          : TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      headerClassName: GridCustomHeaderClass.BorderRight,
      minWidth: SMALL_CELL_WIDTH,
    },
    GridColumn({
      field: columns('distance.displayValue'),
      headerName: `Distance \n(${TEXT.UNIT_MEASUREMENT.NAUTICAL_MILE})`,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueField: columns('distance.fieldValue'),
      minWidth: SMALL_CELL_WIDTH,
    }),
    GridColumn({
      field: columns('speed.displayValue'),
      headerName: `Speed \n(${TEXT.UNIT_MEASUREMENT.KNOTS})`,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueField: columns('speed.fieldValue'),
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    {
      field: columns('fuelConsumed.displayValue'),
      headerName: `Fuel \nConsumed \n(${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) =>
        params.row.fuelConsumed.fieldValue ?? TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      minWidth: SMALL_CELL_WIDTH,
    },
    GridColumn({
      field: columns('co2Emissions.displayValue'),
      headerName: `Total \nCO₂ \n(${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`,
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      valueField: columns('co2Emissions.fieldValue'),
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
    }),
    GridColumn({
      field: columns('calculations.eua.euCo2.displayValue'),
      headerName: 'EUA \nCO₂ \n(t)',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      minWidth: SMALL_CELL_WIDTH,
      valueField: columns('calculations.eua.euCo2.fieldValue'),
      valueFormatter: (params) => euaExcelDisplayValue(params, 2),
    }),
    GridColumn({
      field: columns('calculations.eua.voyageType.displayValue'),
      headerName: 'EU \nVoyage',
      type: 'string',
      align: 'right',
      headerAlign: 'right',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 2,
      valueField: columns('calculations.eua.voyageType.fieldValue'),
      valueFormatter: (params: any) => euaExcelDisplayValue(params),
    }),
    GridColumn({
      field: columns('calculations.eua.liability.fieldValue'),
      headerName: 'EUA \nLiability',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 0,
      renderHeader: euaLiabilitiesTooltipHeader,
      renderCell: (params: any) => {
        const row = params.row as IVoyageComparisonLegDomainWithStatus;
        const value = row?.calculations?.eua?.liability?.fieldValue;
        const euaByYearValues = row?.calculations?.eua?.euaByYear;
        const euaLiabilitiesTooltip = euaByYearValues?.map(
          ({ liability, year }, index) => (
            <React.Fragment key={index}>
              <Box>
                EUA ({year}):{' '}
                {formatNumber(
                  parseFloat(liability?.fieldValue?.toString() ?? '0'),
                  2
                )}
              </Box>
            </React.Fragment>
          )
        );
        return (
          <Tooltip
            title={
              crossingYearsFeatureFlagEnabled
                ? euaLiabilitiesTooltip
                : euaLiabilityDisplayValue(value, 2)
            }
          >
            <span className='MuiDataGrid-cellContent pointer'>
              {euaLiabilityDisplayValue(value, 0)}
            </span>
          </Tooltip>
        );
      },
      valueField: columns('calculations.eua.liability.fieldValue'),
      isNACheck: (params) => !params.row.calculations.eua.liability.fieldValue,
      valueFormatter: (params: any) => euaExcelDisplayValue(params),
    }),
  ];
}

function euaLiabilityDisplayValue(value: any, decimal: number) {
  if (value === null || value === undefined) {
    return NA_VALUE_INDICATOR;
  } else if (stringToNumber(value?.toString() ?? '0') === -1) {
    return TEXT.ERROR_MESSAGES.EMPTY_DASHES;
  }
  return formatNumber(value, decimal);
}

export function euaExcelDisplayValue(
  params: GridValueFormatterParams<any>,
  noDecimals = 0
) {
  const fieldValue = params.value;
  const fieldValueAsNumber = stringToNumber(fieldValue?.toString() ?? '0');
  if (fieldValueAsNumber === -1) {
    return TEXT.ERROR_MESSAGES.EMPTY_DASHES;
  }
  return formatNumber(fieldValue, noDecimals);
}

export function VoyageComparison() {
  const { utcDateRange } = useContext(NavBarContext);
  const { id } = useParams();

  const { data, loading, error } = useGetVoyageComparison(id, utcDateRange);
  const crossingYearsFeatureFlagEnabled = useGetFeatureFlag(
    '2.2_71716_EUA_Voyage_CrossingYears'
  ).data?.isEnabled;
  const columns = getColumnsDefinition(crossingYearsFeatureFlagEnabled);
  const translatedData = data?.legs?.map(
    (x): IVoyageComparisonLegDomainWithStatus => {
      return {
        ...x,
        realCalculations: {
          cii: {
            value: x.calculations.cii.value.displayValue,
            status: x.calculations.cii.status,
          },
          eeoiDeviation: {
            value: x.calculations.eeoi.deviation.displayValue,
            status: x.calculations.eeoi.status,
          },
        },
      };
    }
  );

  const extractKeysForFiltering = ({
    calculations: { cii, eeoi, aer },
  }: IVoyageComparisonLegDomain) => ({
    cii,
    eeoi,
    aer,
  });
  const filterResult = useFilter<IVoyageComparisonLegDomain>(
    translatedData ?? [],
    extractKeysForFiltering
  );

  return (
    <CardContainer title={vesselPageTitles.voyageLegsComparison}>
      <RAGFilter
        activeFilters={filterResult.activeFilters}
        handleToggle={filterResult.handleFilter}
      ></RAGFilter>
      <DataGridTable
        name={vesselPageTitles.voyageLegsComparisonId}
        rows={filterResult.filteredData}
        columns={columns}
        loading={loading}
        error={error}
      ></DataGridTable>
    </CardContainer>
  );
}

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Toolbar,
  Modal,
  Typography,
  IconButton,
} from '@mui/material';
import { Section } from 'shared/components/section/section.component';
import { ReactNode, useContext, useState } from 'react';
import {
  selectStyle,
  compare,
  DownloadListVessel,
  formControlStyleLong,
  formControlStyle,
  generateButtonStyle,
  generateLineItemBoxStyle,
} from './download-common';
import { TopNavigationContext } from 'shared/models/topNav.context.model';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { DownloadLineItemContext } from './download-line-item-context';
import { useGetFeatureFlag } from 'shared/services/featureFlag.service';
import { useMutation } from '@apollo/client';
import { SUBMIT_EMISSION_REPORT } from '../../queries/submit-report.mutation';
import { useTheme } from '@mui/material/styles';
import { modalStyle } from 'shared/components/modal/modal';
import { Close } from '@mui/icons-material';

type LineItemGeneratorProps = {
  children: ReactNode;
  itemTypes: string[];
  title: string;
  inputTypeName: string;
  generateButtonText: string;
  submitButtonText?: string;
};

export const LineItemGenerator = ({
  itemTypes,
  title,
  inputTypeName,
  generateButtonText,
  children,
  submitButtonText,
}: LineItemGeneratorProps) => {
  const theme = useTheme();
  const { handleGenerateLineItems } = useContext(DownloadLineItemContext);
  const { vessels } = useContext(TopNavigationContext);
  const { utcDateRange } = useContext(NavBarContext);
  const [itemType, setItemType] = useState<string>(itemTypes[0] ?? '');
  const [selectedVessels, setSelectedVessels] = useState<DownloadListVessel[]>(
    []
  );
  const sortedVessels = vessels.sort(compare);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isAllSelected =
    selectedVessels.length > 0 && vessels.length === selectedVessels.length;

  const handleItemTypeChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setItemType(value);
  };
  const handleVesselSelectChange = (
    event: SelectChangeEvent<DownloadListVessel[]>
  ) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === 'Select all') {
      setSelectedVessels(
        selectedVessels.length === vessels.length ? [] : vessels
      );
      return;
    }
    setSelectedVessels(value as DownloadListVessel[]);
  };

  const [handleSubmitClicked, { loading }] = useMutation(
    SUBMIT_EMISSION_REPORT,
    {
      variables: {
        request: {
          startDate: utcDateRange?.dateRangeAsString?.startDate,
          endDate: utcDateRange?.dateRangeAsString?.endDate,
          vesselImos: selectedVessels.map((x) => x.imoNumber),
        },
      },
      onError: () => {
        setIsModalOpen(true);
      },
      onCompleted: () => {
        setIsModalOpen(true);
      },
    }
  );

  const erpFeatureFlagEnabled = useGetFeatureFlag(
    '2.1_42280_Direct ERP Submission'
  ).data?.isEnabled;

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Section
        exportSection={false}
        title={title}
        childComponent={
          <Box sx={{ flexDirection: 'column' }}>
            <Toolbar disableGutters>
              <Box sx={generateLineItemBoxStyle}>
                <FormControl sx={formControlStyleLong} size='small'>
                  <InputLabel id={`${inputTypeName}-input-label`}>
                    {inputTypeName}
                  </InputLabel>
                  <Select
                    id={`${inputTypeName}-selector`}
                    labelId={`${inputTypeName}-selector-label`}
                    value={itemType}
                    label={title}
                    onChange={handleItemTypeChange}
                    sx={selectStyle}
                  >
                    <MenuItem value={itemTypes[0]}>{itemTypes[0]}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={formControlStyle} size='small'>
                  <InputLabel id={`${inputTypeName}-vessel-input-label`}>
                    Vessels
                  </InputLabel>
                  <Select
                    id={`${inputTypeName}-vessel-selector`}
                    labelId={`${inputTypeName}-vessel-selector-label`}
                    data-testid={`${inputTypeName}-vessel-dropdown`}
                    inputProps={{ 'data-testid': 'content-input' }}
                    value={selectedVessels}
                    label='Vessels'
                    onChange={handleVesselSelectChange}
                    sx={selectStyle}
                    input={<OutlinedInput label='Vessel' />}
                    renderValue={(selected) => {
                      if (selected.length > 0) {
                        const vesselNames = selected.map((x) => x.name);
                        return vesselNames.join(', ');
                      }
                      return;
                    }}
                    multiple
                  >
                    {sortedVessels.length > 0 && (
                      <MenuItem value={'Select all'}>
                        <Checkbox checked={isAllSelected} />
                        <ListItemText primary={'Select all'} />
                      </MenuItem>
                    )}
                    {sortedVessels.length > 0 &&
                      sortedVessels.map(
                        (vessel: DownloadListVessel, index: number) => (
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          //@ts-ignore: necessary to load object into value
                          <MenuItem value={vessel} key={index}>
                            <Checkbox
                              checked={selectedVessels.indexOf(vessel) > -1}
                            />
                            <ListItemText primary={vessel.name} />
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
                <Button
                  variant='outlined'
                  sx={generateButtonStyle}
                  onClick={() => {
                    handleGenerateLineItems(selectedVessels);
                  }}
                  disabled={selectedVessels.length === 0 || loading}
                >
                  {generateButtonText}
                </Button>
                {submitButtonText && erpFeatureFlagEnabled && (
                  <Button
                    variant='outlined'
                    sx={generateButtonStyle}
                    onClick={() => handleSubmitClicked()}
                    disabled={selectedVessels.length === 0 || loading}
                  >
                    {submitButtonText}
                  </Button>
                )}
              </Box>
            </Toolbar>
            {children}
          </Box>
        }
      />
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={modalStyle(theme.background.component)}>
          <Box sx={{ p: 3 }}>
            <Box sx={{ pb: 1.5 }}>
              <Box sx={{ height: '24px', display: 'flex', width: '100%' }}>
                <Typography sx={{ fontSize: '20px' }}>
                  IMO DCS/MRV report has been submitted to ABS ERP
                </Typography>
                <IconButton
                  sx={{
                    width: '24px',
                    height: '24px',
                    marginLeft: 'auto',
                  }}
                  onClick={handleCloseModal}
                >
                  <Close />
                </IconButton>
              </Box>
            </Box>
            <Typography sx={{ mt: 2 }}>
              Please login to ABS ERP to review the data submitted for each
              registered vessel.
            </Typography>
            <Box sx={{ py: 1.5, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant='contained'
                sx={{
                  fontSize: '16px',
                  py: 1,
                  width: '67px',
                  height: '40px',
                  borderRadius: '8px',
                  textTransform: 'capitalize',
                }}
                onClick={handleCloseModal}
              >
                Done
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

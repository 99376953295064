export const dashes = '---';

export const formatNumberForDisplay = (
  value: number | string | undefined | null,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
  returnDashes = true // if you don't want dashes, pass false
): string => {
  if (value === undefined || value === null) return returnDashes ? dashes : '';

  const num = typeof value === 'string' ? parseFloat(value) : value;
  const formatted = new Intl.NumberFormat('en-US', {
    useGrouping: true,
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  }).format(num);

  if (formatted === 'NaN') return returnDashes ? dashes : '';

  return formatted;
};

export const showDashesIfEmpty = (value: string | undefined | null): string => {
  if (value === '' || value === undefined || value === null) {
    return '---';
  } else {
    return value;
  }
};

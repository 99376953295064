import { Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetVesselKPIs } from 'routes/environmental-monitor/services/vessel.service';
import { KpiCardError } from 'shared/components/error/kpi-card-error.component';
import { KpiCardSkeleton } from 'shared/components/kpi-card-skeleton/kpi-card-skeleton.component';
import {
  StatusKpiCard,
  StatusKpiCardProps,
} from 'shared/components/status-kpi-card/status-kpi-card.component';
import { TEXT } from 'shared/constants/text';
import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { formatNumber } from 'shared/utils/float-utils';
import { theme } from 'styles/theme';

export const VesselKPI = () => {
  const { id } = useParams();
  const { utcDateRange } = useContext(NavBarContext);
  const { data, loading, error } = useGetVesselKPIs(id, utcDateRange);

  // TODO: Move to view model
  const euaPlannedValue =
    data?.euaAgainstPlanned.tertiaryValue &&
    data?.euaAgainstPlanned.tertiaryValue.toString().length > 0
      ? data?.euaAgainstPlanned.tertiaryValue
      : null;

  const hasPlannedValue: boolean = euaPlannedValue !== null;

  if (data && !hasPlannedValue) {
    data.euaAgainstPlanned.primaryValue = formatNumber(
      data?.euaAgainstPlanned?.secondaryValue,
      0
    );
  }

  const AERCardProps: StatusKpiCardProps = {
    title: 'AER',
    footerType: 'required',
    kpiData: data?.aer,
    footerUnits: TEXT.FORMULAS.AER,
    footer: true,
  };

  const CIIRatingCardProps: StatusKpiCardProps = {
    title: 'CII Rating',
    footerType: 'required',
    kpiData: data?.ciiRating,
    units: data?.ciiRating?.quaternaryValue ?? '',
    footerUnits: TEXT.FORMULAS.CII,
    footer: true,
  };

  const EEOICardProps: StatusKpiCardProps = {
    title: 'EEOI',
    footerType: 'target',
    kpiData: data?.eeoi,
    footerUnits: TEXT.FORMULAS.EEOI,
    footer: true,
  };

  const CIIYearToDateCardProps: StatusKpiCardProps = {
    title: 'CII Year to Date',
    footerType: 'required',
    kpiData: data?.ciiYearToDate,
    units: data?.ciiYearToDate?.quaternaryValue ?? '',
    footerUnits: TEXT.FORMULAS.CII,
    footer: true,
    fixedTimeline: true,
  };

  const CIIForecastCardProps: StatusKpiCardProps = {
    title: 'CII End of Year Forecast',
    footerType: 'required',
    kpiData: data?.ciiForecast,
    units: data?.ciiForecast?.quaternaryValue ?? '',
    footerUnits: TEXT.FORMULAS.CII,
    footer: true,
    fixedTimeline: true,
  };

  const EuaAgainstFleetProps: StatusKpiCardProps = {
    title: 'EU Allowances Against Fleet',
    footerType: 'vessel-fleet',
    kpiData: data?.euaAgainstFleet,
    footer: true,
  };

  const EuaAgainstPlannedProps: StatusKpiCardProps = {
    title: 'EU Allowances Against Planned',
    footerType: 'eua-planned',
    kpiData: data?.euaAgainstPlanned,
    footer: true,
  };

  const EuaNoPlannedProps: StatusKpiCardProps = {
    title: 'EU Allowances',
    footerType: 'default-none',
    kpiData: data?.euaAgainstPlanned,
  };

  const kpiData = [
    AERCardProps,
    CIIRatingCardProps,
    EEOICardProps,
    CIIYearToDateCardProps,
    CIIForecastCardProps,
    EuaAgainstFleetProps,
    hasPlannedValue ? EuaAgainstPlannedProps : EuaNoPlannedProps,
  ];

  const { setCardData } = useContext(ExcelExportContext);

  useEffect(() => {
    setCardData(kpiData);
  }, [loading]);

  if (loading) {
    return <KpiCardSkeleton kpiCards={kpiData} />;
  }

  if (error) {
    return <KpiCardError kpiCards={kpiData} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container columns={12} spacing={2} sx={{ marginTop: 1 }}>
        {kpiData.map((cardProps, _) => (
          <Grid item xs={12} smPlus={6} mdPlus={4} lg={3} key={cardProps.title}>
            <StatusKpiCard {...cardProps} />
          </Grid>
        ))}
      </Grid>
    </ThemeProvider>
  );
};

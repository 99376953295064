export const validExcelSectionNames = [
  'Dashboard-Fleet Breakdown by Vessels',
  'EM-Fleet KPIs',
  'EM-Fleet Breakdown by Vessels',
  'EM-Vessel KPIs',
  'EM-Voyage Legs Comparison',
  'VPM-Fleet KPIs',
  'VPM-Fleet Breakdown by Vessels',
  'VPM-Vessel KPIs',
  'VPM-Vessel Events Log',
  'VDM-Fleet Data Quality Summary',
  'VDM-Fleet Breakdown by Vessels',
  'VDM-Vessel Data Quality Summary',
  'VDM-Vessel Data Quality',
  'Alerts Summary',
  'Alerts Table',
] as const;

const getObj = <T extends readonly string[]>(myArray: T) =>
  Object.fromEntries(myArray.map((prop) => [prop, prop])) as Record<
    T[number],
    T[number]
  >;

export const ExportableSections = getObj(validExcelSectionNames);
export type SectionName = (typeof validExcelSectionNames)[number];

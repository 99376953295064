import { TEXT } from 'shared/constants/text';
import { UTCDate } from 'shared/utils/date-utc-helper';
export const yAxisTitle = 'Average EEOI ' + TEXT.FORMULAS.EEOI + ' (Deviation)';

const currentDate = new UTCDate();
const currentYear = currentDate.year!;

export const getDomain = (): { min: number; max: number } => {
  return {
    min: UTCDate.create(currentYear, 0, 1, 0, 0, 0).unixTime!,
    max: UTCDate.create(currentYear, 11, 1, 0, 0, 0).unixTime!,
  };
};

export const getXAxisTicks = (): number[] => {
  const ticks = [];
  for (let i = 0; i < 12; i++) {
    ticks.push(UTCDate.create(currentYear, i, 1, 0, 0, 0).unixTime!);
  }
  return ticks;
};

export const xAxisTickFormatter = (unix: number): string => {
  if (!unix) {
    return '';
  }
  const dateObject = new UTCDate(unix).formatMY();
  return dateObject;
};

import { DataPoint, Maybe } from '_gql/graphql';
import { IVoyageComparisonLegDomain } from '../models/voyage-comparison.model';
import { UTCDate } from 'shared/utils/date-utc-helper';
import { useGetFeatureFlag } from 'shared/services/featureFlag.service';

export const yAxisTitle = 'EUA CO2 (t)';

export type CustomBarRectangleItem = {
  key?: Maybe<string>;
  value?: Maybe<number>;
  withinBarItem?: Maybe<number>;
  inOutBarItem?: Maybe<number>;
  naBarItem?: Maybe<number>;
  planned?: Maybe<number>;
  ytdTotal?: Maybe<number>;
  monthHasData?: Maybe<boolean>;
  previousMonthHasData?: Maybe<boolean>;
};

export type EuaChartData = {
  rawData?: Maybe<DataPoint[]>;
  data?: Maybe<CustomBarRectangleItem[]>;
  inOutData?: Maybe<CustomBarRectangleItem[]>;
  withinData?: Maybe<CustomBarRectangleItem[]>;
  naData?: Maybe<CustomBarRectangleItem[]>;
  ytdTotal?: Maybe<number>;
  planned?: Maybe<number>;
  yMax?: Maybe<number>;
};
export enum CustomBarRectangleItemType {
  InOut,
  Within,
  NA,
}
const minX = 0;
const maxX = 1;
export const xAxisTickFormatter = (unix: number) => {
  if (!unix) {
    return '';
  }
  const dateObject = new UTCDate(unix).formatM();
  return dateObject;
};

export const euaActualStopsGenerator = (
  ytdTotal: number,
  stops: number[],
  plannedValue: number
) => {
  return ytdTotal > plannedValue
    ? stops.map((val) => {
        const plannedPercent = (val * plannedValue) / 100;
        const ytdPercent = (plannedPercent * 100) / ytdTotal;
        return ytdPercent;
      })
    : stops.map((val) => {
        return val;
      });
};
export const euaBoundaryChartDataGenerator = (
  ytdTotal: number,
  stops: number[],
  maxY: number
) => {
  const ciiBoundaryChartData = [
    {
      positionLeft: minX,
      positionRight: maxX,
      maxValue: ytdTotal,
      aKey: 'A',
      aValue: (ytdTotal * stops[0]!) / 100,
      bKey: 'B',
      bValue: ytdTotal * (stops[1]! / 100) - ytdTotal * (stops[0]! / 100),
      cKey: 'C',
      cValue: maxY,
    },
  ];
  return ciiBoundaryChartData;
};

export const generateReferenceAreaData = (
  ytdTotal: number,
  stops: number[],
  yMax: number
) => {
  const x = [
    {
      position: 'left',
      aValue: (ytdTotal * stops[0]!) / 100,
      bValue: ytdTotal * (stops[1]! / 100) - ytdTotal * (stops[0]! / 100),
      cValue: yMax!,
    },
    {
      position: 'right',
      aValue: (ytdTotal * stops[0]!) / 100,
      bValue: ytdTotal * (stops[1]! / 100) - ytdTotal * (stops[0]! / 100),
      cValue: yMax,
    },
  ];
  return x;
};

export function GenerateBarItem(
  inpt: DataPoint[],
  typ: CustomBarRectangleItemType
): CustomBarRectangleItem[] {
  return inpt.map((x) => {
    const ret: CustomBarRectangleItem = {
      key: x.date?.toString() ?? '',
      inOutBarItem:
        typ === CustomBarRectangleItemType.InOut ? x.value ?? 0 : null,
      withinBarItem:
        typ === CustomBarRectangleItemType.Within ? x.value ?? 0 : null,
      naBarItem: typ === CustomBarRectangleItemType.NA ? x.value ?? 0 : null,
    };
    return ret;
  });
}
export function GenerateChartData(chartData: EuaChartData): EuaChartData {
  const data: CustomBarRectangleItem[] = [];
  let monthlyMax = 0;
  let cumulativeTotal = 0;
  let prevMonthHasData = false;
  chartData.rawData?.forEach((x) => {
    const dateString = x.date?.toString();
    const inOut = chartData.inOutData?.find((y) => y.key === dateString);
    const within = chartData.withinData?.find((y) => y.key === dateString);
    const na = chartData.naData?.find((y) => y.key === dateString);
    const val = x.value === 0 ? null : x.value;
    const numberVal = Number(val?.toString().replace(',', ''));

    if (!isNaN(numberVal)) {
      cumulativeTotal += numberVal;
    }
    if (monthlyMax < (val ?? 0)) monthlyMax = val ?? 0;
    const obj: CustomBarRectangleItem = {
      key: dateString ?? '',
      value: val ?? null,
      inOutBarItem: inOut?.inOutBarItem ?? null,
      withinBarItem: within?.withinBarItem ?? null,
      planned: chartData.planned ?? 0,
      ytdTotal: cumulativeTotal,
      monthHasData:
        (inOut?.inOutBarItem !== undefined && inOut?.inOutBarItem! > 0) ||
        (within?.withinBarItem !== undefined && within?.withinBarItem! > 0) ||
        (na?.naBarItem !== undefined && na?.naBarItem! > 0),
      previousMonthHasData: prevMonthHasData,
    };
    if (obj.monthHasData) {
      prevMonthHasData = true;
    }
    data.push(obj);
  });
  chartData.data = data;
  chartData.ytdTotal = cumulativeTotal;
  if (chartData.yMax ?? 0 < cumulativeTotal)
    chartData.yMax =
      cumulativeTotal > 22000
        ? Math.round(cumulativeTotal / 1000 + 10) * 1000
        : Math.round(cumulativeTotal / 100 + 10) * 100;
  if ((chartData.yMax ?? 0) < (chartData.planned ?? 0))
    chartData.yMax = chartData.planned ?? 0;
  return chartData;
}
export function MapVoyageComparisonToDatapoints(
  startDate: UTCDate,
  arr: IVoyageComparisonLegDomain[]
) {
  const year = startDate.year!;
  const ret: DataPoint[] = [];
  const crossingYearsFeatureFlagEnabled = useGetFeatureFlag(
    '2.2_71716_EUA_Voyage_CrossingYears'
  ).data?.isEnabled;
  for (let i = 0; i < 12; i++) {
    const start = UTCDate.create(year, i, 1);
    const end = start.endOfMonth!;
    let total = 0;
    const voyages = (arr ?? []).filter((x) => {
      return (
        new UTCDate(x.departureDate.fieldValue?.toString() ?? '') >= start &&
        new UTCDate(x.departureDate.fieldValue?.toString() ?? '') < end
      );
    });
    voyages.forEach((x) => {
      if (
        parseFloat(
          x.calculations.eua?.liability?.fieldValue?.toString() ?? '0'
        ) !== -1
      ) {
        total += parseFloat(
          x.calculations.eua?.liability?.fieldValue?.toString() ?? '0'
        );
      }
    });

    if (crossingYearsFeatureFlagEnabled) {
      const crossingYearVoyages = (arr ?? []).filter((x) => {
        return (
          new UTCDate(x.departureDate.fieldValue?.toString() ?? '').year !==
            new UTCDate(x.arrivalDate.fieldValue?.toString() ?? '').year &&
          ((new UTCDate(x.departureDate.fieldValue?.toString() ?? '') >=
            start &&
            new UTCDate(x.departureDate.fieldValue?.toString() ?? '') < end) ||
            (new UTCDate(x.arrivalDate.fieldValue?.toString() ?? '') >= start &&
              new UTCDate(x.arrivalDate.fieldValue?.toString() ?? '') < end))
        );
      });
      crossingYearVoyages.forEach((voyage) => {
        if (
          voyage.calculations.eua?.euaByYear &&
          voyage.calculations.eua.euaByYear.length > 0
        ) {
          voyage.calculations.eua.euaByYear.forEach((x) => {
            if (
              parseFloat(x?.liability?.fieldValue?.toString() ?? '0') !== -1 &&
              x.year === year
            ) {
              total += parseFloat(x?.liability?.fieldValue?.toString() ?? '0');
            }
          });
        }
      });
    }

    const dp: DataPoint = { date: start, value: total };
    ret.push(dp);
  }
  return ret;
}

export function MapNAVoyageComparisonToDatapoints(
  startDate: UTCDate,
  arr: IVoyageComparisonLegDomain[]
) {
  const year = startDate.year!;
  const ret: DataPoint[] = [];
  for (let i = 0; i < 12; i++) {
    const start = UTCDate.create(year, i, 1);
    const end = start.endOfMonth!;
    let total = 0;
    const voyages = (arr ?? []).filter((x) => {
      return (
        x.departureDate.fieldValue! >= start &&
        x.departureDate.fieldValue! < end
      );
    });
    voyages.forEach((x) => {
      if (parseFloat(x.co2Emissions.fieldValue?.toString() ?? '0') !== -1) {
        total += parseFloat(x.co2Emissions.fieldValue?.toString() ?? '0');
      }
    });

    const dp: DataPoint = { date: start, value: total };
    ret.push(dp);
  }
  return ret;
}

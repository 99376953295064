import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { DataGridTable } from 'shared/components/datagrid';
import { RAGFilter } from 'shared/components/rag-filter/grid-filter.component';
import { useFilter } from 'shared/components/rag-filter/filter.hook';

import { PlannedItinerarySequence } from './plannedItinerary-sequence.component';
import { Box } from '@mui/material';
import { EnvironmentalKPISummary } from './environmental-KPI-Summary.component';

import { useContext, useState } from 'react';
import { SecondaryButton } from 'shared/components/secondary-button/secondary-button.component';
import { getColumnsDefinition } from './plannedItinerary.config';
import { SequencesModal } from './modal.component';
import { ServiceResult } from 'shared/models/serviceResult.model';
import {
  IPlannedItinerary,
  IPlannedItineraryResults,
} from '../../models/plannedItinerary.model';
import { useGetVoyageComparisonLastPortData } from 'routes/environmental-monitor/services/vessel.service';
import { useParams } from 'react-router-dom';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { PortData } from 'shared/utils/portpolygons-util';
import { useCalculatePlannedItinerary } from 'routes/environmental-monitor/services/plannedItinerary.service';

export const ItineraryComparison = ({
  itineraries,
}: {
  readonly itineraries?: IPlannedItineraryResults[];
}) => {
  const { utcDateRange } = useContext(NavBarContext);
  const { id } = useParams();

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [updatedItinerary, setUpdatedItinerary] = useState<IPlannedItinerary>();
  const [plannedItineraryResults, setplannedItineraryResults] =
    useState<IPlannedItineraryResults>();
  const [itinerary, setItinerary] = useState<IPlannedItinerary>(); // used to display the sequence data in the modal
  const [compareData, setCompareData] = useState<IPlannedItineraryResults[]>(
    itineraries ?? []
  ); // used to display the comparison data in the table
  const [kpiData, setKpiData] =
    useState<ServiceResult<IPlannedItineraryResults>>();

  const results = useCalculatePlannedItinerary(
    id,
    utcDateRange,
    updatedItinerary
  );

  if (
    results &&
    results.data &&
    !results.error &&
    !results.loading &&
    updatedItinerary
  ) {
    setUpdatedItinerary(undefined);
    setKpiData(results);
    setplannedItineraryResults(results.data);
  }

  const { data } = useGetVoyageComparisonLastPortData(id, utcDateRange);
  const lastPortofCall: PortData = {
    Port_Name: data?.portName ?? '',
    UNLOCODE: data?.portCode ?? '',
  };

  const handleClose = () => setModalVisible(false);
  const handleNameClick = (row: IPlannedItineraryResults) => {
    const Itinerary = {
      ...row.plannedItinerary,
      id: row.id,
    } as IPlannedItinerary;
    setItinerary(Itinerary);
    setModalVisible(true);
  };

  const handleCalculate = (itinerary: IPlannedItinerary) => {
    setUpdatedItinerary(itinerary);
    setItinerary(itinerary);
  };

  const handleNewComparison = () => {
    if (plannedItineraryResults && plannedItineraryResults.plannedItinerary) {
      const id = compareData.length + 1;
      plannedItineraryResults.id = id;
      plannedItineraryResults.plannedItinerary.id = id;
      setCompareData([...compareData, plannedItineraryResults]);
    }
  };

  const handleDeleteClick = (Itinerary: IPlannedItineraryResults) => {
    const newData = compareData.filter((row) => row.id !== Itinerary.id);
    setCompareData(newData);
  };

  const addNewSequence = () => {
    const emptyItinerary = {
      lastPortOfCall: lastPortofCall,
    } as IPlannedItinerary;
    setItinerary(emptyItinerary);
    setModalVisible(true);
  };

  const handleSave = (itinerary: IPlannedItinerary) => {
    const recordIndex = compareData.findIndex((c) => c.id === itinerary.id); // this is used to find the record to update
    if (recordIndex >= 0 && plannedItineraryResults) {
      compareData[recordIndex] = plannedItineraryResults;
      setCompareData([...compareData]);
    }
  };

  const extractKeysForFiltering = ({
    cii,
    eeoiDeviation,
    eua,
  }: IPlannedItineraryResults) => ({ cii, eeoiDeviation, eua });

  const filterResult = useFilter<IPlannedItineraryResults>(
    compareData ?? itineraries,
    extractKeysForFiltering
  );

  return (
    <>
      <CardContainer title=''>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <RAGFilter
            activeFilters={filterResult.activeFilters}
            handleToggle={filterResult.handleFilter}
          ></RAGFilter>
          <SecondaryButton
            variant='outlined'
            sx={{ mr: 5 }}
            onClick={addNewSequence}
          >
            {'+ Add New'}
          </SecondaryButton>
        </Box>

        <DataGridTable
          name={'SequenceComparison'}
          rows={filterResult.filteredData}
          columns={getColumnsDefinition(handleNameClick, handleDeleteClick)}
        ></DataGridTable>
      </CardContainer>

      <SequencesModal open={modalVisible}>
        <PlannedItinerarySequence
          onCalculate={handleCalculate}
          onCompare={handleNewComparison}
          onSave={handleSave}
          onClose={handleClose}
          compareData={compareData}
          itineraryData={itinerary}
          isLoading={results.loading}
        />
        <EnvironmentalKPISummary plannedItineraryResults={kpiData} />
      </SequencesModal>
    </>
  );
};

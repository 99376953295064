import { theme } from 'styles/theme';
import * as ExcelJs from 'exceljs';

export const SetColumnWidths = (
  worksheet: ExcelJs.Worksheet,
  EMPTY_ROW: string
) => {
  const maxContentLengths = new Array(worksheet.columns.length).fill(0);
  worksheet.eachRow((row) => {
    row.eachCell({ includeEmpty: true }, (cell, colIndex) => {
      const cellContent = cell.text || EMPTY_ROW;
      const cellContentLength = cellContent.length;
      const columnIndex = colIndex - 1;
      maxContentLengths[columnIndex] = Math.max(
        maxContentLengths[columnIndex],
        cellContentLength
      );
      if (cellContent.length > 60) {
        maxContentLengths[columnIndex] = cellContentLength;

        cell.alignment = { wrapText: true };
      }

      const minWidth = 20;
      const width = Math.max(minWidth, maxContentLengths[columnIndex] + 2);

      worksheet.getColumn(colIndex).width = width;
    });
  });
};

export const getStatusColor = (status: string | undefined) => {
  if (!status) return theme.excelColour.defaultColor;

  switch (status.toLowerCase()) {
    case 'error':
      return theme.excelColour.red;
    case 'success':
      return theme.excelColour.green;
    case 'warning':
      return theme.excelColour.yellow;

    // Alerts
    case 'passed':
      return theme.excelColour.green;
    case 'flagged':
      return theme.excelColour.red;
    case 'open':
      return theme.excelColour.openAlert;
    case 'closed':
      return theme.excelColour.closedAlert;
    case 'archived':
      return theme.excelColour.archivedAlert;

    // VDM
    case 'non-emissions':
      return theme.excelColour.nonEmissionColor;
    case 'emissions':
    case 'multiple':
      return theme.excelColour.emissionColor;

    default:
      return theme.excelColour.defaultColor;
  }
};

const formatCellColor = (cell: ExcelJs.Cell) => {
  const cellobject: any = cell.value;
  const { status, value } = cellobject;
  cell.value = value;
  if (Number.isNaN(value)) {
    cell.value = 0;
  }

  setCellBackgroundColor(cell, getStatusColor(status));
};

const SetBordersToCell = (cell: ExcelJs.Cell) => {
  cell.border = {
    top: { style: 'thin', color: { argb: theme.excelColour.lightgrey } },
    left: { style: 'thin', color: { argb: theme.excelColour.lightgrey } },
    bottom: { style: 'thin', color: { argb: theme.excelColour.lightgrey } },
    right: { style: 'thin', color: { argb: theme.excelColour.lightgrey } },
  };
};

export const setCellBackgroundColor = (cell: ExcelJs.Cell, color?: string) => {
  if (color) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color },
    };
    SetBordersToCell(cell);
  }
};

export const StyleTableGridValues = (
  worksheet: ExcelJs.Worksheet,
  startingIndex: number | undefined,
  colorColumnNumbers: string[] | undefined
) => {
  colorColumnNumbers?.forEach((colNumber) => {
    const columnIndex = Number(colNumber);
    worksheet.eachRow((row, rowNumber) => {
      if (startingIndex && rowNumber > startingIndex) {
        const cell = row.getCell(columnIndex);
        const cellobject = cell.value;

        if (cellobject && typeof cellobject === 'object') {
          formatCellColor(cell);
        }
      }
    });
  });
};

import { createContext } from 'react';
import { Dayjs } from 'dayjs';
import { DownloadListVessel } from 'routes/reports/components/generateReport/download-common';
import { DateRange } from 'shared/utils/date-utc-helper';

export interface INavBarContext {
  imoNumbers: string[];
  setImoNumbers: Function;
  vessels: DownloadListVessel[];
  setVessels: Function;
  dateRange: {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  };
  utcDateRange: DateRange | null;
  setDateRange: Function;
  setUTCDateRange: Function;
  alertCount: number;
  setAlertCount: Function;
}

export const navBarContext: INavBarContext = {
  imoNumbers: [],
  vessels: [],
  setImoNumbers: () => {},
  setVessels: () => {},
  dateRange: {
    startDate: null,
    endDate: null,
  },
  utcDateRange: null,
  setDateRange: () => {},
  setUTCDateRange: () => {},
  alertCount: 0,
  setAlertCount: () => {},
};

export const NavBarContext = createContext<INavBarContext>(navBarContext);

import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { ColorBar } from './eua-ratings-color-bar.component';
import { theme } from 'styles/theme';
import {
  euaActualStopsGenerator,
  euaBoundaryChartDataGenerator,
} from '../../../view-models/eua-ratings-chart.viewModel';

export const LeftColorBar = (props: {
  euaPlannedValue: number;
  ytdTotal: number;
  stops: number[];
  yMax: number;
}) => {
  const plannedValue = props.euaPlannedValue;
  const stops = props.stops;
  const yMax = props.yMax;
  const actualStops = euaActualStopsGenerator(
    props.ytdTotal,
    stops,
    plannedValue
  );

  const ciiBoundariesChartData = euaBoundaryChartDataGenerator(
    plannedValue,
    actualStops,
    yMax
  );
  return (
    <ResponsiveContainer height={415}>
      <BarChart
        data={ciiBoundariesChartData}
        margin={{ top: 20, bottom: 60, left: 25 }}
      >
        <XAxis
          dataKey={'positionLeft'}
          textAnchor='end'
          domain={[0, 1]}
          type='number'
          axisLine={false}
          tickLine={false}
          tick={false}
        />
        <YAxis
          allowDataOverflow
          fontSize={12}
          dataKey='maxValue'
          tickLine={false}
          tick={false}
          tickMargin={10}
          domain={[0, yMax]}
          type='number'
          axisLine={false}
          dx={-12}
          width={75}
        />

        <Bar
          dataKey='aValue'
          stackId='a'
          fill={theme.colors?.aRating}
          shape={<ColorBar dataKey='aValue' />}
        ></Bar>
        <Bar
          dataKey='bValue'
          stackId='a'
          fill={theme.colors?.bRating}
          shape={<ColorBar dataKey='bValue' />}
        ></Bar>
        <Bar
          dataKey='cValue'
          stackId='a'
          fill={theme.colors?.eRating}
          shape={<ColorBar dataKey='cValue' />}
        ></Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

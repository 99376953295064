import { ApolloError } from '@apollo/client';
import { KpiStatus } from '_gql/graphql';
import { IRagFilterData } from 'routes/dashboard/models/fleet-kpis.model';
import { VesselDetailsTileProps } from 'routes/vessel-data-monitor/mappers/export-excel-data.mapper';
import { StatusKpiCardProps } from 'shared/components/status-kpi-card/status-kpi-card.component';
import { UTCDate } from 'shared/utils/date-utc-helper';

export type Vessel = {
  vesselId: string;
  vesselName: string;
  overallPassScorePercentage: number | undefined;
  overallPassScore: number | undefined;
  overallFailScore: number | undefined;
  kpiStatus: KpiStatus;
  sisterClass?: string;
  vesselType?: string;
  vesselGroup?: string;
  id?: string;
  isValid?: boolean;
  nonEmissionDataFailed?: boolean;
  reportId?: string;
  reportType?: string;
  reportedDate?: UTCDate | string;
  dateModified?: UTCDate | string;
  emissionDataFailed?: boolean;
  tiles?: VesselDetailsTileProps[];
  status?: string;
  emission?: string;
  nonEmission?: string;
};

export interface IVessel extends Vessel {
  realStatus?: IRagFilterData;
}

export interface FilterProps {
  filter: string[];
  handleToggle: (
    event: React.MouseEvent<HTMLElement>,
    newFilter: string[]
  ) => void;
}

export type SortDirection = 'asc' | 'desc';

export interface CellHeader {
  id: keyof Vessel | number;
  label: string;
}
export type FleetDataComparisonTableProps = {
  loading: boolean;
  error: ApolloError | undefined;
  filterProps: FilterProps;
  filteredData: Vessel[];
  handleRequestSort: (property: keyof Vessel | number) => void;
  sortBy: keyof Vessel;
  sortDirection: SortDirection;
};

export type VesselDataMonitorSummaryProps = {
  overallPassedScoreCardProps: StatusKpiCardProps;
  passedCardProps: StatusKpiCardProps;
  failedCardProps: StatusKpiCardProps;
  loading: boolean;
  error: ApolloError | undefined;
  filteredData: Vessel[];
};

export const scoreThresholdToLabel = (status: string) => {
  return status === KpiStatus.Error
    ? 'warning'
    : status === KpiStatus.Warning
    ? 'atRisk'
    : 'onTarget';
};

import { PageHeader } from 'shared/components/page-header/pageHeader.component';
import PageWrapper from 'shared/components/page-wrapper/page-wrapper.component';
import { LineItemGenerator } from './generateReport/line-item-generator';
import { ReactComponent as ExcelIcon } from 'assets/excel-icon.svg';
import { ReactComponent as PdfIcon } from 'assets/pdf-icon.svg';
import { DownloadLineItemList } from './generateReport/download-line-item-list';
import { ReportModalWarning } from './generateReport/report-modal-warning';
import { DownloadLineItemContextProvider } from './generateReport/download-line-item-context';
import { useAvailableReportsAndStatements } from '../services/available-reports-and-statements';

export const Report = () => {
  const { availableReports, availableStatements } =
    useAvailableReportsAndStatements();

  return (
    <PageWrapper data-testid={'reports'}>
      <PageHeader title='Manage Reports' />
      <DownloadLineItemContextProvider>
        <LineItemGenerator
          itemTypes={availableReports}
          title='Generate Report'
          inputTypeName='Report Type'
          generateButtonText='Generate Report'
          submitButtonText='Direct Submission to ABS ERP'
        >
          <DownloadLineItemList
            downloadButtonText='Download Report'
            icon={<ExcelIcon />}
          />
        </LineItemGenerator>

        <ReportModalWarning />
      </DownloadLineItemContextProvider>
      <DownloadLineItemContextProvider>
        <LineItemGenerator
          itemTypes={availableStatements}
          title='Generate Statement'
          inputTypeName='Statement Type'
          generateButtonText='Generate Statement'
        >
          <DownloadLineItemList
            downloadButtonText='Download Statement'
            icon={<PdfIcon />}
          />
        </LineItemGenerator>
      </DownloadLineItemContextProvider>
    </PageWrapper>
  );
};

import { Box, Typography } from '@mui/material';
import { UTCDate } from 'shared/utils/date-utc-helper';
export const CustomTooltip = (props: any) => {
  const titleStyle = {
    color: 'rgba(29, 38, 47, 0.87)',
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '14px' /* 140% */,
    display: 'inline',
  };

  if (props.active && props.payload && props.payload.length) {
    const dateInfo = new UTCDate(props.label);
    const ciiInfoArr = props.payload;
    ciiInfoArr.sort((a: any, b: any) => {
      return a.dataKey.localeCompare(b.dataKey);
    });
    return (
      <Box
        sx={{
          fontSize: '10px',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          backgroundColor: '#EFEFF0',
          color: 'rgba(29, 38, 47, 0.87)',
          padding: '8px',
          borderRadius: '4px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            margin: 0,
            lineHeight: '14px',
            fontSize: '12px',
            mb: '4px',
          }}
        >
          {dateInfo?.formatMY()}
          <br />
          Fleet Breakdown:
        </Typography>

        {ciiInfoArr.map((item: any, index: number) => (
          <Box
            key={index}
            sx={{ display: 'flex', alignItems: 'center', height: '15px' }}
          >
            <span
              style={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: item.fill,
              }}
            ></span>

            <Box style={{ margin: '0 4px' }}>
              <Typography sx={titleStyle}>{item.dataKey}:</Typography>{' '}
              {item.payload[item.dataKey]} Vessels
            </Box>
          </Box>
        ))}
      </Box>
    );
  }

  return null;
};

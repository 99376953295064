import {
  EnvironmentalMonitor,
  EnvironmentalMonitorVoyageComparison,
  EuaAgainstPlannedKpi,
  EuaByYear,
  KpiStatus,
  VoyageType,
} from '_gql/graphql';
import { Status } from 'shared/models/status.type';
import { formatNumberForDisplay } from 'shared/utils/display-utils';
import { toStatusEnum } from 'shared/utils/status-utils';
import {
  EuaAgainstPlannedKpiDomain,
  IEnvironmentalMonitorDomain,
  IEuaByYear,
  IVoyageComparisonField,
  IVoyageComparisonLegDomain,
  IPortDataDomain,
} from '../models/voyage-comparison.model';
import { headers } from '../view-models/voyage-comparison.viewModel';
import { TEXT } from 'shared/constants/text';
import { NA_VALUE_INDICATOR } from 'shared/components/datagrid/DataGrid.component';
import { UTCDate } from 'shared/utils/date-utc-helper';

export class VoyageComparisonMapper {
  public static fromDTO(
    dto: EnvironmentalMonitor | undefined
  ): IEnvironmentalMonitorDomain | undefined {
    const legs = dto?.voyageComparison
      ?.map((leg) => this.legFromDTO(leg))
      ?.filter((domainLeg) => domainLeg !== undefined);
    return {
      vesselName: dto?.vesselName ?? NA_VALUE_INDICATOR,
      legs,
    };
  }
  public static euaAgainstPlannedFromDto(
    kpis: EuaAgainstPlannedKpi | null
  ): EuaAgainstPlannedKpiDomain {
    return {
      status: kpis?.status ?? KpiStatus.Error,
      value: kpis?.value ?? 0,
      vesselEuaCo2: kpis?.vesselEuaCo2 ?? 0,
      vesselEuaPlannedValue: kpis?.vesselEuaPlannedValue ?? 0,
    };
  }

  public static legFromDTO(
    leg: EnvironmentalMonitorVoyageComparison
  ): IVoyageComparisonLegDomain {
    return {
      rowId: Number(leg.leg) ?? 0,
      legNumber: this.fieldFromDTO(leg.leg ?? null, 'legNumber'),
      departurePort: this.fieldFromDTO(
        leg.departurePort ?? null,
        'departurePort'
      ),
      departureDate: this.fieldFromDTO(
        leg.departureDate ? leg.departureDate.formatDMY() : null,
        'departureDate'
      ),
      arrivalPort: this.fieldFromDTO(leg.arrivalPort ?? null, 'arrivalPort'),
      arrivalDate: this.fieldFromDTO(
        leg.arrivalDate ? leg.arrivalDate.formatDMY() : null,
        'arrivalDate'
      ),
      calculations: {
        cii: {
          value: this.fieldFromDTO(
            leg.calculations?.cii?.ciiRating ?? null,
            'calculations.cii.value'
          ),
          delta: this.fieldFromDTO(
            leg.calculations?.cii?.delta ?? null,
            'calculations.cii.delta'
          ),
          deviation: this.fieldFromDTO(
            !leg.calculations?.cii?.deviation
              ? null
              : leg.calculations?.cii?.deviation.toFixed(),
            'calculations.cii.deviation'
          ),
          status: this.setStatusProperty(leg.calculations?.cii?.status),
        },
        ciiPercentage: {
          deviation: this.fieldFromDTO(
            !leg.calculations?.ciiPercentage?.deviation
              ? null
              : leg.calculations?.ciiPercentage?.deviation.toFixed(),
            'calculations.ciiPercentage.deviation'
          ),
          status: this.setStatusProperty(
            leg.calculations?.ciiPercentage?.status
          ),
        },
        eeoi: {
          value: this.fieldFromDTO(
            leg.calculations?.eeoi?.actual ?? null,
            'calculations.eeoi.value'
          ),
          delta: this.fieldFromDTO(
            leg.calculations?.eeoi?.delta ?? null,
            'calculations.eeoi.delta'
          ),
          deviation: this.fieldFromDTO(
            !leg.calculations?.eeoi?.deviation
              ? null
              : leg.calculations?.eeoi?.deviation.toFixed(),
            'calculations.eeoi.deviation'
          ),
          status: this.setStatusProperty(leg.calculations?.eeoi?.status),
        },
        aer: {
          value: this.fieldFromDTO(
            leg.calculations?.aer?.attained ?? null,
            'calculations.aer.value'
          ),
          delta: this.fieldFromDTO(
            leg.calculations?.aer?.delta ?? null,
            'calculations.aer.delta'
          ),
          deviation: this.fieldFromDTO(
            !leg.calculations?.aer?.deviation
              ? null
              : leg.calculations?.aer?.deviation.toFixed(),
            'calculations.aer.deviation'
          ),
          status: this.setStatusProperty(leg.calculations?.aer?.status),
        },
        eua: {
          euCo2: this.fieldFromDTO(
            !leg?.calculations ? -1 : leg.calculations?.eua?.euCo2 ?? null,
            'calculations.eua.euCo2'
          ),
          liability: this.fieldFromDTO(
            !leg?.calculations ? -1 : leg.calculations?.eua?.liability ?? null,
            'calculations.eua.liability'
          ),
          voyageType: this.fieldFromDTO(
            !leg?.calculations
              ? -1
              : leg.calculations?.eua?.voyageType === null ||
                leg.calculations?.eua?.voyageType === undefined
              ? null
              : VoyageComparisonMapper.mapVoyageTypeToString(
                  leg.calculations?.eua?.voyageType
                ),
            'calculations.eua.voyageType'
          ),
          status: this.setStatusProperty(leg.calculations?.eua?.status),
          euaByYear: leg.calculations?.eua?.euaByYear?.map((x) =>
            this.euaByYearFromDTO(x)
          ),
        },
      },
      distance: this.fieldFromDTO(leg.distance ?? null, 'distance'),
      speed: this.fieldFromDTO(leg.speed ?? null, 'speed'),
      fuelConsumed: this.fieldFromDTO(leg.fuelConsumed ?? null, 'fuelConsumed'),
      co2Emissions: this.fieldFromDTO(leg.co2Emissions ?? null, 'co2Emissions'),
    };
  }

  public static fieldFromDTO(
    value: string | number | UTCDate | null,
    field: string
  ): IVoyageComparisonField {
    const fieldInfo = headers.find((header) => header.field === field);
    if (!fieldInfo) {
      return {
        fieldValue: value,
        displayValue: TEXT.ERROR_MESSAGES.EMPTY_DASHES,
      };
    }

    const displayZero = fieldInfo.displayZero;

    let displayValue = TEXT.ERROR_MESSAGES.EMPTY_DASHES;
    if (value !== null && (displayZero === true || value !== 0)) {
      if (typeof value === 'string' || field === 'legNumber') {
        displayValue = value.toString();
      } else if (typeof value === 'number') {
        displayValue = formatNumberForDisplay(value, 0, 2, true);
      } else if (value instanceof UTCDate) {
        displayValue = value.formatDMY();
      }
    }
    return {
      fieldValue: value,
      displayValue: displayValue,
    };
  }

  public static euaByYearFromDTO(y: EuaByYear): IEuaByYear {
    return {
      euCo2: this.fieldFromDTO(y.euCo2 ?? null, 'euCo2'),
      liability: this.fieldFromDTO(y.liability ?? null, 'liability'),
      year: y.year,
    };
  }

  private static setStatusProperty(status: string | null | undefined): Status {
    return status ? toStatusEnum(status) : 'unknown';
  }

  private static mapVoyageTypeToString(voyageType: VoyageType): string {
    switch (voyageType) {
      case VoyageType.InOut:
        return 'In/Out';
      case VoyageType.NonEu:
        return 'Non-EU';
      case VoyageType.Within:
        return 'Within';
      case VoyageType.Unavailable:
        return 'Data unavailable';
      case VoyageType.InProgress:
        return 'In Progress';
      default:
        return '';
    }
  }

  public static lastDeparturePortFromDTO(
    dto: EnvironmentalMonitor | undefined
  ): IPortDataDomain | undefined {
    const legs = dto?.voyageComparison;
    // Check if legs is defined and not empty
    if (!legs || legs.length === 0) {
      return undefined;
    }

    // Sort legs by leg field
    const sortedLegs = [...legs].sort((a, b) => a.leg - b.leg);

    // Get the last element in the sorted array
    const lastLeg = sortedLegs[sortedLegs.length - 1];

    // Return the relevant port data
    return {
      portName: lastLeg?.departurePort ?? '',
      portCode: lastLeg?.departurePortCode ?? '',
    };
  }
}

import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  KpiData,
  customFooterType,
  footerType,
} from '../status-kpi-card.component';
import { typography } from 'styles/typography';
import { formatNumber } from 'shared/utils/float-utils';

export interface KpiCardFooterProps {
  footerType?: footerType;
  kpiData: KpiData | undefined;
  footerUnits?: string | undefined;
  footerSize?: string | undefined;
}

const renderCardFooter = (config: {
  title: string;
  titleValue: any;
  titleUnits: string;
  subTitle: string;
  subValue: any;
  subTitleUnits: string;
}) => {
  const { fontFamilies, fontWeights, lineHeights, fontSize, letterSpacing } =
    typography;

  const subscriptStyle: SxProps = {
    alignSelf: 'end',
    ml: 0.5,
    fontFamily: fontFamilies.roboto,
    fontSize: fontSize['1'],
    fontWeight: fontWeights['roboto-2'],
    letterSpacing: letterSpacing['5'],
    lineHeight: lineHeights['7'],
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex' }}>
        <Typography variant='body1' sx={{ pr: 2 }}>
          {config.title}
        </Typography>
        <Typography variant='body1' sx={{ ml: 'auto' }}>
          {config.titleValue}
        </Typography>
        <Typography sx={subscriptStyle}>{config.titleUnits}</Typography>
      </Box>

      <Box sx={{ display: 'flex', pt: 2 }}>
        <Typography variant='body1' sx={{ pr: 2 }}>
          {config.subTitle}
        </Typography>
        <Typography variant='body1' sx={{ ml: 'auto' }}>
          {config.subValue}
        </Typography>
        <Typography sx={subscriptStyle}>{config.subTitleUnits}</Typography>
      </Box>
    </Box>
  );
};

export const getCardTitles = (props: KpiCardFooterProps) => {
  let title = '';
  let subTitle = '';
  let noDecimals = 2;
  const footerType: footerType = props.footerType;

  // check if this is a custom footer
  if ((footerType as customFooterType).title) {
    const customFooter = footerType as customFooterType;
    return {
      title: customFooter.title,
      subTitle: customFooter.subTitle,
      noDecimals: noDecimals,
      titleUnits: customFooter.titleUnits ?? props.footerUnits ?? '',
      subTitleUnits: customFooter.subTitleUnits ?? props.footerUnits ?? '',
    };
  }

  switch (footerType) {
    case 'required':
      title = 'Attained';
      subTitle = 'Required';
      break;

    case 'target':
      title = 'Actual';
      subTitle = 'Target';
      break;

    case 'planned':
      title = 'Liability';
      subTitle = 'Planned';
      noDecimals = 0;
      break;

    case 'vessel-fleet':
      title = 'Vessel';
      subTitle = 'Fleet';
      noDecimals = 0;
      break;

    case 'eua-planned':
      title = 'Actual';
      subTitle = 'Planned';
      noDecimals = 0;
      break;
  }

  return {
    title: title,
    subTitle: subTitle,
    noDecimals: noDecimals,
    titleUnits: props.footerUnits ?? '',
    subTitleUnits: props.footerUnits ?? '',
  };
};

export const KPICardFooter = (props: KpiCardFooterProps) => {
  const getCard = (props: KpiCardFooterProps) => {
    const settings = getCardTitles(props);
    const titleValue = props.kpiData?.secondaryValue;

    return renderCardFooter({
      title: settings.title,
      titleValue: formatNumber(titleValue, settings.noDecimals),
      titleUnits: settings.titleUnits ?? '',
      subTitle: settings.subTitle,
      subValue: formatNumber(props.kpiData?.tertiaryValue, settings.noDecimals),
      subTitleUnits: settings.subTitleUnits ?? '',
    });
  };

  return <>{getCard(props)}</>;
};

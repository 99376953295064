import { SyncDisabled } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  SxProps,
  Typography,
} from '@mui/material';
import Box from '@mui/system/Box';
import { Status } from 'routes/environmental-monitor/models/gql/vessel-kpi-gql.model';
import { getStatusValues } from 'shared/components/status-kpi-card/status-kpi-card.viewModel';
import { TEXT } from 'shared/constants/text';
import { theme } from 'styles/theme';
import { typography } from 'styles/typography';

export interface KpiCardErrorProps {
  kpiCards: {
    title?: string;
  }[];
}

export function KpiCardError(props: KpiCardErrorProps) {
  const cardBackground = theme.background.component;

  const cardStyle: SxProps = {
    borderRadius: '12px',
    backgroundColor: cardBackground,
    minWidth: '245px',
  };

  const { color: statusBarBackground } = getStatusValues(Status.none);

  const statusBarStyle: SxProps = {
    backgroundColor: statusBarBackground,
    height: 8,
    margin: 0,
  };

  const errorCards = props.kpiCards.map((card, index) => {
    return (
      <Grid
        item
        key={card.title + index.toString()}
        xs={12}
        smPlus={6}
        mdPlus={4}
        lg={3}
        data-testid={'kpi-error-component-' + index.toString()}
      >
        <Card sx={{ ...cardStyle }}>
          <Divider sx={statusBarStyle} />
          <CardContent
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '234px',
              justifyContent: 'center',
              marginTop: '8px',
              marginRight: '24px',
              marginLeft: '24px',
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                overflow: 'hidden',
              }}
            >
              <SyncDisabled
                fontSize={'large'}
                sx={{
                  color: theme.palette.text.disabled,
                  marginBottom: '12px',
                }}
              />
              <Box>
                <Typography
                  sx={{
                    fontSize: typography.fontSize[12],
                    textAlign: 'center',
                  }}
                >
                  {`Unable to load ${card.title} data`}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: theme.palette.text.secondary,
                    fontSize: typography.fontSize[1],
                  }}
                >
                  {TEXT.ERROR_MESSAGES.LOADING_DATA_ACTION}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  });

  return (
    <Grid container columns={12} spacing={2} sx={{ marginTop: 1 }}>
      {errorCards}
    </Grid>
  );
}

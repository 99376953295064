import { Status } from 'shared/models/status.type';
import { KpiStatus } from '_gql/graphql';

export const toStatusEnum = (status: string | undefined): Status => {
  return (status?.toLowerCase() ?? 'error') as Status;
};

export const kpiStatusToEnum = (status: KpiStatus | undefined): Status => {
  switch (status) {
    case KpiStatus.Error:
    case KpiStatus.None:
    case KpiStatus.Success:
    case KpiStatus.Unknown:
    case KpiStatus.Warning:
      return status.toLowerCase() as Status;
    default:
      return 'error';
  }
};

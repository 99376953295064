import { Toolbar } from '@mui/material';
import { FixedTimeline } from 'assets/fixed-timeline';
import { useParams } from 'react-router-dom';
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useGetVesselAer } from 'routes/environmental-monitor/services/vessel.service';
import { ChartKey } from 'shared/components/chart-key/chart-key.component';
import { ErrorComponent } from 'shared/components/error/error.component';
import { LoadingComponent } from 'shared/components/loading/loading.component';
import {
  getChartRangeValues,
  keyProps,
  xAxisTickFormatter,
  xAxisTicks,
  yAxisTickFormatter,
  yAxisTitle,
} from '../../../view-models/aer-chart.viewModel';
import { Tooltip as CustomTooltip } from './tooltip.component';
import { getYAxisTicks } from 'routes/environmental-monitor/view-models/utils/graph-utils';
import { vesselPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';

export function AERChart() {
  const { id } = useParams();
  const startDate = new UTCDate().startOfYear;
  const endOfYear = new UTCDate().endOfYear;
  const endDate = new UTCDate().endOfDay;
  const dateRange = new DateRange(startDate!, endDate!);
  const { data, error, loading } = useGetVesselAer(id, dateRange);
  const aerData = data?.data;

  const hasData = aerData && aerData?.length > 0;

  const renderContent = () => {
    if (error || !hasData) {
      return <ErrorComponent error={error} empty={!hasData} />;
    }

    if (loading) {
      return <LoadingComponent height='415px' />;
    }

    return (
      <>
        <Toolbar disableGutters>
          <ChartKey items={keyProps.items} />
        </Toolbar>

        <ResponsiveContainer width='99%' height={400}>
          {renderChart()}
        </ResponsiveContainer>
      </>
    );
  };

  const renderChart = () => {
    return (
      <LineChart data={aerData} margin={{ top: 20, bottom: 60, right: 20 }}>
        <XAxis
          fontSize={12}
          dataKey='date'
          type='number'
          angle={-35}
          tick={{ fill: '#EFEFF0' }}
          textAnchor='end'
          axisLine={false}
          dy={10}
          padding={{ right: 3 }}
          tickLine={true}
          ticks={xAxisTicks()}
          tickFormatter={xAxisTickFormatter}
          domain={[startDate?.unixTime!, endOfYear?.unixTime!]}
        />
        <YAxis
          allowDataOverflow
          fontSize={12}
          dataKey='value'
          tickLine={false}
          tickFormatter={(val) => yAxisTickFormatter(val, data?.required ?? 0)}
          tick={{ fill: '#EFEFF0' }}
          tickCount={yAxisTicks.length}
          ticks={yAxisTicks}
          interval={0}
          tickMargin={0}
          type='number'
          domain={[chartRangeValues.dataMin, dataMaxCeil]}
          axisLine={false}
          dx={-12}
          width={dataMaxCeil < 20 || deviationMax < 1000 ? 110 : 120}
        >
          <Label
            dy={80}
            fontSize={12}
            fill='#FFFFFF'
            position={'insideLeft'}
            offset={10}
            angle={-90}
            value={yAxisTitle}
          />
        </YAxis>
        <YAxis orientation='right' />
        <CartesianGrid
          color={'#FFFFFF'}
          vertical={false}
          strokeDasharray='0'
          opacity={0.14}
        />
        <Tooltip content={<CustomTooltip chartType='AER' />} cursor={false} />
        <Line
          activeDot={true}
          connectNulls={true}
          dataKey='value'
          dot={false}
          stroke='#4DE8E3'
          strokeWidth={3}
          type='linear'
        />
      </LineChart>
    );
  };

  const chartRangeValues = getChartRangeValues(aerData);

  const yAxisTicks = getYAxisTicks(
    chartRangeValues.dataMin,
    chartRangeValues.dataMax
  );
  const dataMaxCeil = Math.ceil(chartRangeValues.dataMax ?? 0);
  const deviationMax = (dataMaxCeil - 5) * 40;

  return (
    <CardContainer
      title={vesselPageTitles.aer}
      icon={<FixedTimeline sx={{ margin: '5px 0px 0px 15px' }} />}
    >
      {renderContent()}
    </CardContainer>
  );
}

import Box from '@mui/material/Box';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  LineChart,
  Line,
  ResponsiveContainer,
} from 'recharts';
import { ApolloError } from '@apollo/client';
import { theme } from 'styles/theme';
import { TEXT } from 'shared/constants/text';
import {
  ChartKey,
  ChartKeyItem,
} from 'shared/components/chart-key/chart-key.component';
import { GroupingFilter } from 'shared/components/grouping-filter/grouping-filter.component';
import { ErrorComponent } from 'shared/components/error/error.component';
import { LoadingComponent } from 'shared/components/loading/loading.component';
import { KpiChartItem } from '../models/fleet-kpi-chart.model';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { DateRange } from 'shared/utils/date-utc-helper';
import { fullDateRangeChartData } from '../models/fleet-kpi-chart.viewModel';

export interface FleetKpiChartProps {
  title: string;
  chartData: {
    loading?: boolean;
    error: ApolloError | undefined;
    data: KpiChartItem[] | undefined;
  };
  callback: (ids: string[]) => void;
  yAxisLabel?: string;
  dateRange: DateRange | null;
}

const axisColor = theme.vesselPerformance.charts.axis;
const toolTipBackground = theme.vesselPerformance.charts.toolTipBackground;

export function FleetKpiChart(props: Readonly<FleetKpiChartProps>) {
  const {
    title,
    chartData,
    callback,
    yAxisLabel = `Excess Fuel (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES_PER_DAY})`,
  } = props;
  const data = fullDateRangeChartData(chartData.data, props.dateRange);
  const loading = chartData.loading;
  const error = chartData.error;

  if (loading) {
    return <LoadingComponent margin-bottom={'24px'} height={'484px'} />;
  }

  const hasData =
    data && data.filter((x) => Object.keys(x).length > 1).length > 0;
  const keys = new Set<string>();
  data?.forEach((x) => {
    Object.keys(x).forEach((y) => (y !== 'date' ? keys.add(y) : null));
  });

  const chartFills = [
    'rgba(255, 167, 38, 1)', // main warning
    'rgba(140, 133, 207, 1)', // secondary purple
    'rgba(112, 232, 199, 1)', // secondary green
    'rgba(255, 89, 140, 1)', // secondary magenta
    'rgba(77, 232, 227, 1)', // secondary cyan
    'rgba(244, 143, 177, 1)', // pink
    'rgba(209, 196, 233, 1)', // deep purple :)
    'rgba(49, 157, 180, 1)', // secondary main
    'rgba(255, 213, 79, 1)', // amber 300
    'rgba(185, 246, 202, 1)', // color #10
  ];

  const chartKeyItems: ChartKeyItem[] = Array.from(keys).map((x, i) => {
    return { id: i, name: x, fill: chartFills[i] ?? 'white' };
  });

  const content = () => {
    if (error || !hasData) {
      return (
        <ErrorComponent error={error} empty={!hasData} heightOverride='348px' />
      );
    }

    return (
      <>
        <ChartKey items={chartKeyItems} />

        <ResponsiveContainer width='99%' height={300}>
          <LineChart
            data={data}
            margin={{ top: 20, right: 10, bottom: 38, left: 10 }}
          >
            <XAxis
              dataKey='date'
              dy={12}
              angle={-35}
              textAnchor='end'
              axisLine={false}
              tickLine={true}
              tick={{ fill: axisColor }}
              style={{ ...theme.typography.chart }}
              interval={'preserveStartEnd'}
            />
            <YAxis
              dx={-12}
              axisLine={false}
              tickLine={false}
              tick={{ fill: axisColor }}
              style={{ ...theme.typography.chart }}
            >
              <Label
                fill={axisColor}
                style={{ ...theme.typography.chart, textAnchor: 'middle' }}
                position={'insideLeft'}
                angle={-90}
                value={yAxisLabel}
              />
            </YAxis>
            <CartesianGrid
              vertical={false}
              strokeDasharray='0'
              opacity={0.12}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: toolTipBackground,
                color: theme.background.page,
                borderRadius: '4px',
              }}
              itemStyle={{ color: 'secondary.dark' }}
            />
            {chartKeyItems.map((c) => (
              <Line
                key={c.id}
                type='linear'
                dot={false}
                dataKey={c.name}
                stroke={c.fill}
                connectNulls
                strokeWidth={3}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </>
    );
  };

  return (
    <CardContainer title={title}>
      <Box sx={{ p: 3 }}>
        {content()}
        <Box sx={{ mt: 5 }}>
          {hasData ?? <GroupingFilter callback={callback} />}
        </Box>
      </Box>
    </CardContainer>
  );
}

import { Box, Grid, IconButton, MenuItem, SxProps } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  GridColumn,
  GridCustomHeaderClass,
  nameof,
} from 'shared/components/datagrid';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { StatusStyle } from 'routes/alerts/components/alert-table/alert-styles';
import { TEXT } from 'shared/constants/text';
import { PortSelectionInput } from './port-selection/port-selection.component';
import {
  EmptyOption,
  ListEnum,
  ValidatedTextField,
} from 'shared/components/inputs/input-fields';
import { IFuelType } from 'routes/environmental-monitor/models/fuelType.model';
import { useGetFuelTypes } from 'routes/environmental-monitor/services/fueltypes.service';
import {
  type ISequence,
  LoadingConditionTypes,
  SequenceTypes,
} from '../../models/plannedItinerary.model';
import { EMPTY_PORT_DATA, PortData } from 'shared/utils/portpolygons-util';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';

export function ListFuelTypes(fuelTypes: IFuelType[]) {
  const menuItems = fuelTypes.map((fuelType) => {
    return (
      <MenuItem key={fuelType.enumValue} value={fuelType.enumValue}>
        {fuelType.abbreviation}
      </MenuItem>
    );
  });

  return menuItems;
}

const gridStyle: SxProps = {
  my: 1,
  '& .MuiGrid-item': {
    pt: 0,
  },
};

export const SequenceRow = (params: GridRenderCellParams<ISequence>) => {
  const { api, row } = params;
  const { data, loading } = useGetFuelTypes();
  const [fuelTypes, setFuelTypes] = useState<IFuelType[]>([]);

  useEffect(() => {
    if (loading === false && data) {
      setFuelTypes(data);
    }
  }, [loading]);

  const fueltypeValue =
    fuelTypes.find((x) => x.enumValue === row.fuelType?.enumValue)?.enumValue ??
    '';

  // Typescript Function that updates the field of the row
  // and calls the api to update the row
  function updateProperty<T>(obj: T, propertyName: keyof T, value: any): T {
    return { ...obj, [propertyName]: value };
  }

  const UpdateRowField = (field: keyof ISequence, value: any) => {
    // Function to copy cargo quantities
    const copyCargoQuantities = (
      targetRow: ISequence | undefined,
      sourceRow: ISequence | undefined
    ) => {
      if (sourceRow?.cargoQuantity !== undefined) {
        if (targetRow) {
          targetRow.cargoQuantity = sourceRow.cargoQuantity;
          targetRow.loadingCondition = LoadingConditionTypes.Laden;
        }
      }
    };

    // Find the last sequence with cargo quantity
    const findLastCargoQuantitySequence = (): ISequence | null => {
      const rowIds = api.getAllRowIds();
      if (!rowIds || !Array.isArray(rowIds)) {
        return null;
      }

      const filteredRows = rowIds
        .map((rowId: any) => api.getRow(rowId))
        .filter(
          (row: any) =>
            (row.sequence === SequenceTypes.InPortDischarge ||
              row.sequence === SequenceTypes.InPortLoading) &&
            row.cargoQuantity !== undefined
        );

      return filteredRows.length > 0 ? filteredRows.pop() : null;
    };

    // We need to reset the sequence field when it is changed, so no leftover values are present
    if (field === 'sequence') {
      const emptyRecord: ISequence = {
        id: row.id,
        sequence: value,
      } as ISequence;

      // Clear all other fields when sequence has changed
      let member: keyof ISequence;
      for (member in params.row) {
        if (member === 'id' || member === 'sequence') continue;
        delete params.row[member as keyof ISequence];
      }

      // Copy cargo quantity if needed
      if (value !== 'InPortDischarge' && value !== 'InPortLoading') {
        const lastCargoQuantitySequence = findLastCargoQuantitySequence();
        if (lastCargoQuantitySequence) {
          copyCargoQuantities(emptyRecord, lastCargoQuantitySequence);
        }
      }

      api.updateRows([emptyRecord]);
      return;
    }
    const updatedRow = updateProperty(row, field, value);
    api.updateRows([updatedRow]);
  };

  if (params.row.sequence === EmptyOption.Undefined) {
    return (
      <Grid container spacing={2} sx={gridStyle}>
        <Grid item xs={2}>
          <ValidatedTextField
            label={`Sequence ${params.row.id}`}
            select
            value={params.row.sequence ?? ''}
            required
            onChange={(e) => UpdateRowField('sequence', e.target.value)}
          >
            {ListEnum(SequenceTypes)}
          </ValidatedTextField>
        </Grid>
      </Grid>
    );
  }

  const showPortInput = !(
    params.row.sequence === SequenceTypes.AtSea ||
    params.row.sequence === SequenceTypes.Drifting ||
    params.row.sequence === SequenceTypes.Maneuvering
  );

  const showDurationInput = !(
    params.row.sequence === SequenceTypes.AtSea ||
    params.row.sequence === SequenceTypes.Maneuvering
  );

  const showSpeedAndDistance =
    params.row.sequence === SequenceTypes.AtSea ||
    params.row.sequence === SequenceTypes.Maneuvering;

  return (
    <Grid container spacing={2} sx={gridStyle}>
      <Grid item xs={2}>
        <ValidatedTextField
          label={`Sequence ${params.row.id}`}
          select
          required
          value={params.row.sequence ?? ''}
          sx={showErrorBorder(params.row.sequenceFailingField, 'sequence')}
          onChange={(e) => UpdateRowField('sequence', e.target.value)}
        >
          {ListEnum(SequenceTypes)}
        </ValidatedTextField>
      </Grid>

      <Grid item xs>
        <ValidatedTextField
          label={'Loading Condition'}
          select
          required
          value={params.row.loadingCondition ?? ''}
          sx={showErrorBorder(
            params.row.sequenceFailingField,
            'loadingCondition'
          )}
          onChange={(e) => UpdateRowField('loadingCondition', e.target.value)}
          itemID={params.row.sequence}
        >
          {ListEnum(LoadingConditionTypes)}
        </ValidatedTextField>
      </Grid>

      {showSpeedAndDistance && (
        <>
          <Grid item xs>
            <ValidatedTextField
              label={`Distance (${TEXT.UNIT_MEASUREMENT.NAUTICAL_MILE})`}
              required
              value={params.row.distance ?? ''}
              onChange={(e) => UpdateRowField('distance', e.target.value)}
              fieldType='number'
              itemID={params.row.sequence}
            />
          </Grid>

          <Grid item xs>
            <ValidatedTextField
              label={`Speed (${TEXT.UNIT_MEASUREMENT.KNOTS})`}
              required
              value={params.row.speed ?? ''}
              onChange={(e) => UpdateRowField('speed', e.target.value)}
              fieldType='number'
              itemID={params.row.sequence}
            />
          </Grid>
        </>
      )}

      {showPortInput && (
        <Grid item xs>
          <PortSelectionInput
            onMenuChange={(newValue) => UpdateRowField('port', newValue)}
            required
            value={(params.row.port as PortData) ?? EMPTY_PORT_DATA}
            label={'Port'}
            itemID={params.row.sequence}
          />
        </Grid>
      )}

      {showDurationInput && (
        <Grid item xs>
          <ValidatedTextField
            label={`Duration (${TEXT.UNIT_MEASUREMENT.HOUR})`}
            required
            value={params.row.duration ?? ''}
            onChange={(e) => UpdateRowField('duration', e.target.value)}
            fieldType='number'
            inputProps={{ min: 0, step: 1 }}
            itemID={params.row.sequence}
          />
        </Grid>
      )}

      <Grid item xs>
        <ValidatedTextField
          label={'Fuel Type'}
          select
          required
          value={fueltypeValue}
          onChange={(e) =>
            UpdateRowField(
              'fuelType',
              fuelTypes.find((x) => x.enumValue === Number(e.target.value))
            )
          }
          itemID={params.row.sequence}
        >
          {ListFuelTypes(fuelTypes)}
        </ValidatedTextField>
      </Grid>

      <Grid item xs>
        <ValidatedTextField
          label={`FOC (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES_PER_DAY})`}
          required
          value={params.row.foc ?? ''}
          onChange={(e) => UpdateRowField('foc', e.target.value)}
          fieldType='number'
          itemID={params.row.sequence}
        />
      </Grid>

      <Grid item xs>
        <ValidatedTextField
          label={'Cargo Quantity'}
          required
          value={params.row.cargoQuantity ?? ''}
          sx={showErrorBorder(params.row.sequenceFailingField, 'cargoQuantity')}
          onChange={(e) => UpdateRowField('cargoQuantity', e.target.value)}
          fieldType='number'
          itemID={params.row.sequence}
        />
      </Grid>
    </Grid>
  );
};

function showErrorBorder(
  failingField: keyof ISequence | undefined,
  fieldName: keyof ISequence
) {
  return failingField === fieldName
    ? { border: `1px solid ${theme.palette.error.main}`, borderRadius: '5px' }
    : {};
}

export function getColumnsDefinition(
  handleDeleteClick: (id: string | number) => void
): GridColDef[] {
  const columns = nameof<ISequence>;

  const columnDefinition = [
    GridColumn({
      field: 'selectRow',
      align: 'left',
      valueField: 'isValid',
      headerName: '',
      cellClassName: GridCustomHeaderClass.NoGutters,
      headerClassName: GridCustomHeaderClass.NoGutters,
      maxWidth: 10,
      visibility: 'screen-only',
      renderCell: (params: GridRenderCellParams<ISequence>) => {
        return (
          <Box
            sx={{
              ...StatusStyle(
                false,
                (params.row.sequenceFailingField?.length ?? 0) > 0
              ),
              height: '100%',
            }}
          ></Box>
        );
      },
    }),
    GridColumn({
      field: 'sequenceRow',
      headerName: 'Status',
      headerAlign: 'center',
      type: 'string',
      valueField: columns('sequence'),
      visibility: 'screen-only',
      renderCell: (params: GridRenderCellParams<ISequence>) => {
        return SequenceRow(params);
      },
    }),

    GridColumn({
      maxWidth: 50,
      field: 'delete',
      visibility: 'screen-only',
      renderCell: (params: GridRenderCellParams<ISequence>) => {
        const noRows = params.api.getRowsCount();
        if (noRows === 1) {
          return null;
        }

        return (
          <IconButton
            aria-label='delete'
            onClick={() => handleDeleteClick(params.row.id)}
          >
            <CancelIcon />
          </IconButton>
        );
      },
    }),

    GridColumn({
      field: columns('sequence'),
      headerName: 'Sequence',
      headerAlign: 'center',
      type: 'string',
      visibility: 'export-only',
      valueField: columns('sequence'),
    }),
    GridColumn({
      field: columns('loadingCondition'),
      headerAlign: 'center',
      type: 'string',
      visibility: 'export-only',
      valueField: columns('loadingCondition'),
    }),
    GridColumn({
      field: columns('distance'),
      headerName: `Distance (${TEXT.UNIT_MEASUREMENT.NAUTICAL_MILE})`,
      type: 'string',
      visibility: 'export-only',
      valueField: columns('distance'),
    }),
    GridColumn({
      field: columns('speed'),
      headerName: `Speed (${TEXT.UNIT_MEASUREMENT.KNOTS})`,
      type: 'string',
      visibility: 'export-only',
      valueField: columns('speed'),
    }),
    GridColumn({
      field: columns('port.Port_Name'),
      headerName: 'Port',
      type: 'string',
      visibility: 'export-only',
      valueField: columns('port.Port_Name'),
    }),
    GridColumn({
      field: columns('duration'),
      headerName: `Duration (${TEXT.UNIT_MEASUREMENT.HOUR})`,
      type: 'string',
      visibility: 'export-only',
      valueField: columns('duration'),
    }),
    GridColumn({
      field: columns('fuelType.abbreviation'),
      headerName: 'Fuel Type',
      type: 'string',
      visibility: 'export-only',
      valueField: columns('fuelType.abbreviation'),
    }),
    GridColumn({
      field: columns('foc'),
      headerName: `FOC (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES_PER_DAY})`,
      type: 'string',
      visibility: 'export-only',
      valueField: columns('foc'),
    }),
    GridColumn({
      field: columns('cargoQuantity'),
      type: 'string',
      visibility: 'export-only',
      valueField: columns('cargoQuantity'),
    }),
  ];

  return columnDefinition;
}
export { ISequence };

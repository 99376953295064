import { ServiceResult } from 'shared/models/serviceResult.model';
import { useCalculatePlannedItineraryData } from '../repositories/plannedItinerary.repository';
import {
  IPlannedItinerary,
  IPlannedItineraryResults,
} from '../models/plannedItinerary.model';
import { PlannedItineraryResultsMapper } from '../mappers/plannedItinerary.mapper';
import { DateRange } from 'shared/utils/date-utc-helper';

export const useCalculatePlannedItinerary = (
  vesselId: string | undefined,
  dateRange: DateRange | null,
  plannedItinerary: IPlannedItinerary | undefined
): ServiceResult<IPlannedItineraryResults> => {
  try {
    const response = useCalculatePlannedItineraryData(
      vesselId,
      dateRange,
      plannedItinerary
    );
    const loading = response?.loading;
    const error = response?.error;
    let mappedData: IPlannedItineraryResults | undefined = undefined;
    if (response?.data) {
      mappedData = PlannedItineraryResultsMapper.toDomain(
        response?.data?.vessel?.plannedItineraryCalculations ?? undefined,
        plannedItinerary
      );
    }

    return {
      data: mappedData,
      loading,
      error,
      refetch: response?.refetch,
    };
  } catch (error) {
    return {
      loading: false,
      data: undefined,
      error: undefined,
      refetch: () => {},
    };
  }
};

import { useContext, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { DateRange as MuiDateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import Toolbar from '@mui/material/Toolbar';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { AuthenticatedContent } from 'routes/login/components/AuthenticatedContent';
import { VesselGroupingsDropdown } from 'shared/components/vesselGroupingsDropdown/vesselGroupingsDropdown.component';
import { DateRange as DateRangeComponent } from 'shared/components/date-range/dateRange.component';
import { theme } from 'styles/theme';
import { StyledToolbar } from './styledToolbar.component';
import { useSearchParams } from 'react-router-dom';
import { UTCDate, DateRange } from 'shared/utils/date-utc-helper';

type TopNavProps = {
  open: boolean;
};
export const TopNavigation: React.FC<TopNavProps> = ({ open }) => {
  const [searchParams] = useSearchParams();
  const startQuery = searchParams.get('start');
  const endQuery = searchParams.get('end');

  const yearStart = DateRange.yearStart(startQuery);
  const today = DateRange.today(endQuery);

  const { dateRange, setDateRange } = useContext(NavBarContext);
  const { utcDateRange, setUTCDateRange } = useContext(NavBarContext);

  const handleDateChange = (newValue: MuiDateRange<Dayjs>) => {
    setDateRange(newValue[0], newValue[1]);
    setUTCDateRange(
      new DateRange(new UTCDate(newValue[0]), new UTCDate(newValue[1]))
    );
  };

  useEffect(() => {
    if (dateRange.startDate === null && dateRange.endDate === null) {
      setDateRange(yearStart, today);
    }
  }, [dateRange, setDateRange, yearStart, today]);

  useEffect(() => {
    utcDateRange === null &&
      setUTCDateRange(
        new DateRange(new UTCDate(yearStart), new UTCDate(today))
      );
  }, [utcDateRange, setUTCDateRange, yearStart, today]);

  const drawerIndex = theme.zIndex.drawer;

  return (
    <AppBar
      position='static'
      sx={{
        zIndex: drawerIndex - 1,
        backgroundColor: theme.background.page,
      }}
    >
      <StyledToolbar disableGutters open={open}>
        <VesselGroupingsDropdown />
        <Box sx={{ mx: 4, ml: 1.5, width: '100%' }}>
          <Toolbar
            disableGutters
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <DateRangeComponent
              initialRange={[yearStart, today]}
              handler={handleDateChange}
            />
            <AuthenticatedContent />
          </Toolbar>
        </Box>
      </StyledToolbar>
    </AppBar>
  );
};

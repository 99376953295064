import { Box, Typography } from '@mui/material';
import { formatNumber } from 'shared/utils/float-utils';
import { xAxisTickFormatter } from 'routes/environmental-monitor/view-models/eeoi-chart.viewModel';

export const CustomTooltip = (props: any) => {
  if (props.active && props.payload?.length && props.payload[0].payload) {
    const value = props.payload[0].payload.eeoiDeviation;
    const deviation = formatNumber(value);
    const month = xAxisTickFormatter(props.label);

    return (
      <Box
        sx={{
          fontSize: '10px',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          backgroundColor: '#EFEFF0',
          color: 'rgba(29, 38, 47, 0.87)',
          padding: '8px',
          borderRadius: '4px',
        }}
      >
        <Typography
          sx={{
            flexGrow: 1,
            textAlign: 'center',
            fontWeight: 'bold',
            lineHeight: '14px',
            fontSize: '12px',
            mb: '2px',
          }}
        >
          {`EEOI Deviation: ${deviation}%`}
          <br />
          {`Voyage Legs Completed: ${props.payload[0].payload.voyageLegsCompleted}`}
          <br />
          {`${month}`}
        </Typography>
      </Box>
    );
  }
  return null;
};

import { Grid } from '@mui/material';
import {
  StatusKpiCard,
  StatusKpiCardProps,
} from 'shared/components/status-kpi-card/status-kpi-card.component';
import { useContext, useEffect } from 'react';
import { getAlertStatusColor } from 'routes/alerts/models/alert.viewModel';
import { useGetAlertCount } from 'routes/alerts/services/alerts.service';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { ApolloError, NetworkStatus } from '@apollo/client';
import { Status } from 'shared/models/status.type';
import { KpiCardSkeleton } from 'shared/components/kpi-card-skeleton/kpi-card-skeleton.component';
import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import { AlertStatus } from '../../services/alert-status';
import { StatusCardError } from 'shared/components/status-card/status-card-error.component';
import { TEXT } from 'shared/constants/text';
import { DateRange, UTCDate } from 'shared/utils/date-utc-helper';
import { formatNumber } from 'shared/utils/float-utils';

interface AlertCountResponse {
  loading: boolean;
  error: ApolloError | undefined;
  data: number | null | undefined;
}

// This component is responsible for displaying the alert KPI card
const AlertKPICard = (props: {
  response: AlertCountResponse;
  status: string;
  title: string;
}) => {
  const { response, status, title } = props;

  if (response.error)
    return (
      <StatusCardError
        message={`Unable to load ${title}`}
        subtext={TEXT.ERROR_MESSAGES.LOADING_DATA_ACTION}
      />
    );

  if (response.loading) return <KpiCardSkeleton kpiCards={[{}]} />;

  const open: StatusKpiCardProps = {
    kpiData: {
      primaryValue: formatNumber(response?.data),
      status: status as Status,
    },
    title: title,
  };

  return (
    <StatusKpiCard {...open} customGetStatusValues={getAlertStatusColor} />
  );
};

export const AlertSummary = ({
  forceUpdate,
}: {
  forceUpdate?: UTCDate | undefined;
}) => {
  const { imoNumbers, utcDateRange, setAlertCount } = useContext(NavBarContext);

  const openCardData = useGetAlertCount({
    vesselImoNumbers: imoNumbers,
    dateRange: utcDateRange ?? new DateRange(),
    alertStatuses: [AlertStatus.Open],
    isArchived: false,
  });
  const closedCardData = useGetAlertCount({
    vesselImoNumbers: imoNumbers,
    dateRange: utcDateRange ?? new DateRange(),
    alertStatuses: [AlertStatus.Closed],
    isArchived: false,
  });
  const archivedCardData = useGetAlertCount({
    vesselImoNumbers: imoNumbers,
    dateRange: utcDateRange ?? new DateRange(),
    isArchived: true,
  });
  const { setCardData } = useContext(ExcelExportContext);

  const alertDataResponse = useGetAlertCount({
    dateRange: new DateRange(new UTCDate('2000-01-01'), new UTCDate()),
    alertStatuses: [AlertStatus.Open],
    isArchived: false,
    isRead: false,
  });

  // Refetch data when forceUpdate is triggered
  const refetchDataIfNeeded = (data: any) => {
    if (
      data.loading === false &&
      data.networkStatus !== NetworkStatus.refetch
    ) {
      data.refetch();
    }
  };

  useEffect(() => {
    if (forceUpdate) {
      refetchDataIfNeeded(openCardData);
      refetchDataIfNeeded(closedCardData);
      refetchDataIfNeeded(archivedCardData);
      refetchDataIfNeeded(alertDataResponse);
    }
  }, [forceUpdate]);

  // Update the alert count in the navbar
  useEffect(() => {
    setAlertCount(alertDataResponse.data ?? 0);
  }, [alertDataResponse.data]);

  useEffect(() => {
    const newData = [
      { title: 'Open', value: openCardData.data },
      { title: 'Closed', value: closedCardData.data },
      { title: 'Archived', value: archivedCardData.data },
    ];
    setCardData(newData);
  }, [openCardData.data, closedCardData.data, archivedCardData.data]);

  return (
    <Grid container spacing={3} sx={{ marginTop: 0 }}>
      <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
        <AlertKPICard
          response={openCardData}
          status={'success'}
          title={'Open'}
        />
      </Grid>
      <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
        <AlertKPICard
          response={closedCardData}
          status={'error'}
          title={'Closed'}
        />
      </Grid>
      <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
        <AlertKPICard
          response={archivedCardData}
          status={'primary'}
          title={'Archived'}
        />
      </Grid>
    </Grid>
  );
};

import { Grid } from '@mui/material';
import { useGetFleetKpis } from 'routes/environmental-monitor/services/fleet.service';
import { KpiCardError } from 'shared/components/error/kpi-card-error.component';
import { KpiCardSkeleton } from 'shared/components/kpi-card-skeleton/kpi-card-skeleton.component';
import {
  StatusKpiCard,
  StatusKpiCardProps,
} from 'shared/components/status-kpi-card/status-kpi-card.component';
import { TEXT } from 'shared/constants/text';
import { toStatusEnum } from 'shared/utils/status-utils';
import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import { useContext, useEffect } from 'react';
import { NA_VALUE_INDICATOR } from 'shared/components/datagrid/DataGrid.component';
import { formatNumber } from 'shared/utils/float-utils';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { DateRange } from 'shared/utils/date-utc-helper';

export type FleetKpiDataSummaryProps = {
  dateRange: DateRange | null;
};

export const FleetKpiDataSummary: React.FC<FleetKpiDataSummaryProps> = ({
  dateRange,
}) => {
  const { setCardData } = useContext(ExcelExportContext);
  const { imoNumbers } = useContext(NavBarContext);

  const { data, loading, error } = useGetFleetKpis(dateRange, imoNumbers);

  const EuaPLannedCardTitle = 'EU Allowances Against Planned';
  const EuaNotPlannedCardTitle = 'EU Allowances';
  const EuaErrorTitle = 'EUA';
  const EeoiCardTitle = 'EEOI';

  const liability = data?.kpis?.eua?.liability;
  const isNoReportsError = liability === -1;
  const planned = data?.kpis?.eua?.planned;
  const status = toStatusEnum(data?.kpis?.eua?.status ?? '');

  const isPlanned = planned && planned > 0 ? true : false;
  const liabilityPercent = data?.kpis?.eua?.value ?? undefined;
  const eeoiActual = data?.kpis?.eeoi?.actual;
  const eeoiTarget = data?.kpis?.eeoi?.target;
  const eeoiDeviation = data?.kpis?.eeoi?.deviation;
  const eeoiStatus = toStatusEnum(data?.kpis?.eeoi?.status ?? '');
  const EUACardProps: StatusKpiCardProps = {
    title: EuaPLannedCardTitle,
    footer: true,
    footerType: 'planned',
    kpiData: {
      primaryValue:
        isNoReportsError === true
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : `${
              isPlanned && liabilityPercent
                ? liabilityPercent
                : NA_VALUE_INDICATOR
            }`, // EUA Val
      secondaryValue:
        isNoReportsError === true
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : liability != null
          ? liability?.toFixed()
          : NA_VALUE_INDICATOR, // Liability
      tertiaryValue: `${planned || 0}`, // Planned
      quaternaryValue: '', // Deviation
      status: isPlanned && liabilityPercent ? status : 'unknown',
      unit: TEXT.UNIT_MEASUREMENT.PERCENTAGE,
    },
  };

  const passedCardProps: StatusKpiCardProps = {
    title: EuaNotPlannedCardTitle,
    kpiData: {
      status: liability ? 'graph' : 'unknown',
      primaryValue: formatNumber(liability),
    },
  };

  const EeoiCardProps: StatusKpiCardProps = {
    title: EeoiCardTitle,
    footer: true,
    footerType: 'target',
    footerUnits: TEXT.FORMULAS.EEOI,
    kpiData: {
      primaryValue: formatNumber(eeoiDeviation),
      secondaryValue: formatNumber(eeoiActual, 2),
      tertiaryValue: formatNumber(eeoiTarget, 2),
      status: eeoiDeviation ? eeoiStatus : 'unknown',
      unit: TEXT.UNIT_MEASUREMENT.PERCENTAGE,
    },
  };
  useEffect(() => {
    setCardData([isPlanned ? EUACardProps : passedCardProps, EeoiCardProps]);
  }, [loading]);

  if (error) {
    return (
      <KpiCardError
        kpiCards={[{ title: EuaErrorTitle }, { title: EeoiCardTitle }]}
      />
    );
  }

  if (loading || data === undefined)
    return <KpiCardSkeleton kpiCards={[{ footer: true }, { footer: true }]} />;

  return (
    <Grid container spacing={2} sx={{ marginTop: 1 }} data-testid='kpi-grid'>
      {isPlanned && (
        <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
          <StatusKpiCard {...EUACardProps} />
        </Grid>
      )}
      {!isPlanned && (
        <Grid item xs={12} smPlus={6} mdPlus={4} lg={3}>
          <StatusKpiCard {...passedCardProps} />
        </Grid>
      )}
      {
        <Grid item xs={12} smPlus={6} mdPlus={4} lg={3} data-testid='kpi-eeoi'>
          <StatusKpiCard {...EeoiCardProps} />
        </Grid>
      }
    </Grid>
  );
};

import { Link, Tooltip, Typography } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { TEXT } from 'shared/constants/text';
import { useGetVessels } from '../../services/vessel.service';
import { useContext } from 'react';
import {
  DataGridTable,
  GridColumn,
  GridColumnDef,
  GridCustomHeaderClass,
  GridRAGColumn,
  nameof,
  objvalue,
} from 'shared/components/datagrid';
import { RAGDisplay } from 'shared/components/status-card/ragIndicator.component';
import { IFleetVessel } from 'routes/environmental-monitor/models/fleet-vessel.model';
import { KpiStatus } from '_gql/graphql';
import { Status } from 'shared/models/status.type';
import { RAGFilter } from 'shared/components/rag-filter/grid-filter.component';
import { useFilter } from 'shared/components/rag-filter/filter.hook';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'router/router';
import { fleetPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';

import { euaLiabilitiesTooltipHeader } from '../tooltip/eua-tooltip.component';
import { formatNumber, isNumeric } from 'shared/utils/float-utils';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { euaExcelDisplayValue } from '../vessel-detail/voyage-comparison/voyage-comparison.component';
import { DateRange } from 'shared/utils/date-utc-helper';

export type FleetOverviewTableProps = {
  dateRange: DateRange | null;
};
export function convertKpiStatusToStatus(source: KpiStatus): Status {
  switch (source) {
    case KpiStatus.Success:
      return 'success';
    case KpiStatus.Warning:
      return 'warning';
    case KpiStatus.Error:
      return 'error';
    case KpiStatus.None:
      return 'unknown';
    case KpiStatus.Unknown:
      return 'unknown';
  }
}

const SMALL_CELL_WIDTH = 135;
const BIG_CELL_WIDTH = 180;

const renderCellRAG = (params: any) => {
  const base = params.colDef as GridColumnDef;
  const noDecimals = base.noDecimals ?? 0;
  const valueField = base.valueField ?? base.field + '.value';
  const statusField = base.statusField ?? base.field + '.status';
  const rawValue = objvalue(params.row, valueField);
  const value = formatNumber(rawValue, noDecimals);
  const status = objvalue(params.row, statusField);
  const realStatus = convertKpiStatusToStatus(status);
  return <RAGDisplay value={value} status={realStatus} />;
};

const GridRagColumnValue = (base: GridColumnDef) =>
  GridRAGColumn({
    ...base,
    minWidth: base.minWidth ?? BIG_CELL_WIDTH,
    renderCell: renderCellRAG,
  });

const GridColumnValue = (base: GridColumnDef) =>
  GridColumn({
    ...base,
    type: 'number',
    noDecimals: base.noDecimals ?? 2,
    minWidth: base.minWidth ?? BIG_CELL_WIDTH,
  });

const GridRagColumnDelta = (base: GridColumnDef) =>
  GridColumn({
    ...base,
    minWidth: SMALL_CELL_WIDTH,
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    noDecimals: base.noDecimals ?? 2,
  });

const GridRagColumnDeviation = (base: GridColumnDef) =>
  GridRAGColumn({
    ...base,
    type: 'number',
    minWidth: base.minWidth ?? SMALL_CELL_WIDTH,
    renderCell: renderCellRAG,
  });

function getColumnsDefinition(navigate: any): GridColumnDef[] {
  const columns = nameof<IFleetVessel>; // get the properties from the model

  const handleVesselNameClick = (row: any) => {
    navigate(`${ROUTES.environmentalMonitor.path}/vessels/${row.id}`);
  };

  const commonSection: GridColumnDef[] = [
    {
      field: columns('name'),
      headerName: 'Vessel Name',
      flex: 1,
      minWidth: BIG_CELL_WIDTH,
      renderCell: (params: any) => (
        <Link
          className='MuiDataGrid-cellContent'
          onClick={() => handleVesselNameClick(params.row)}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: columns('class'),
      headerName: 'Sister Class',
      minWidth: BIG_CELL_WIDTH,
      headerClassName: GridCustomHeaderClass.BorderRight,
    },
  ];

  const CIISection: GridColumnDef[] = [
    GridRagColumnValue({
      field: columns('calculations.cii.value'),
      headerName: 'CII \nRating',
      type: 'string',
      headerAlign: 'center',
      valueField: columns('calculations.cii.value'),
      statusField: columns('calculations.cii.status'),
    }),
    GridRagColumnDelta({
      field: columns('calculations.cii.delta'),
      headerName: 'CII \nΔ',
      minWidth: 100,
      valueField: columns('calculations.cii.delta'),
    }),
    GridRagColumnDeviation({
      field: columns('calculations.ciiPercentage.deviation'),
      headerName: 'CII \n%',
      headerClassName: GridCustomHeaderClass.BorderRight,
      valueField: columns('calculations.ciiPercentage.deviation'),
      statusField: columns('calculations.ciiPercentage.status'),
    }),
  ];

  const EEOISection: GridColumnDef[] = [
    GridColumnValue({
      field: columns('calculations.eeoi.value'),
      headerName: 'EEOI \n(gCO₂/t-NM)',
      valueField: columns('calculations.eeoi.value'),
    }),

    GridRagColumnDelta({
      field: columns('calculations.eeoi.delta'),
      headerName: 'EEOI \nΔ',
      valueField: columns('calculations.eeoi.delta'),
    }),

    GridRagColumnDeviation({
      field: columns('calculations.eeoi.deviation'),
      headerName: 'EEOI \n%',
      headerClassName: GridCustomHeaderClass.BorderRight,
      valueField: columns('calculations.eeoi.deviation'),
      statusField: columns('calculations.eeoi.status'),
    }),
  ];

  const AERSection: GridColumnDef[] = [
    GridColumnValue({
      field: columns('calculations.aer.value'),
      headerName: 'AER \n(gCO₂/DWT-NM)',
      valueField: columns('calculations.aer.value'),
    }),

    GridRagColumnDelta({
      field: columns('calculations.aer.delta'),
      headerName: 'AER \nΔ',
      valueField: columns('calculations.aer.delta'),
    }),
    GridRagColumnDeviation({
      field: columns('calculations.aer.deviation'),
      headerName: 'AER \n%',
      headerClassName: GridCustomHeaderClass.BorderRight,
      valueField: columns('calculations.aer.deviation'),
      statusField: columns('calculations.aer.status'),
    }),
  ];

  const otherSection: GridColumnDef[] = [
    GridColumnValue({
      field: columns('fuelConsumed'),
      headerName: `Fuel \nConsumed (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`,
      headerAlign: 'right',
      align: 'right',
      valueField: columns('fuelConsumed'),
    }),
    GridColumnValue({
      field: columns('co2Emissions'),
      headerName: `CO₂ \nEmissions (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`,
      headerAlign: 'right',
      align: 'right',
      minWidth: SMALL_CELL_WIDTH,

      valueField: columns('co2Emissions'),
    }),
    GridColumnValue({
      field: columns('calculations.cii.required'),
      headerName: 'Required CII',
      headerAlign: 'right',
      align: 'right',
      minWidth: SMALL_CELL_WIDTH,
      valueField: columns('calculations.cii.required'),
    }),
    GridColumnValue({
      field: columns('distance'),
      headerName: `Distance \n(${TEXT.UNIT_MEASUREMENT.NAUTICAL_MILE})`,
      headerAlign: 'right',
      align: 'right',
      valueField: columns('distance'),
    }),
  ];

  const EUASection: GridColumnDef[] = [
    GridColumnValue({
      field: columns('calculations.eua.co2'),
      headerName: 'EUA CO₂',
      headerAlign: 'right',
      align: 'right',
      valueField: columns('calculations.eua.co2'),
    }),
    GridColumnValue({
      field: columns('calculations.eua.delta'),
      headerName: 'EUA Δ',
      minWidth: SMALL_CELL_WIDTH,
      type: 'string',
      headerAlign: 'center',
      align: 'center',
      valueField: columns('calculations.eua.delta'),
      renderHeader() {
        const styleProperties: SxProps = {
          fontSize: 'inherit',
          textAlign: 'right',
        };
        return (
          <Box className='MuiDataGrid-columnHeaderTitle'>
            <Typography key={1} sx={styleProperties}>
              EUA
            </Typography>
            <Typography key={2} sx={styleProperties}>
              Δ
            </Typography>
          </Box>
        );
      },
    }),
    GridColumnValue({
      field: columns('calculations.eua.liability'),
      headerName: 'EUA \nLiability',
      headerAlign: 'right',
      align: 'right',
      minWidth: SMALL_CELL_WIDTH,
      noDecimals: 0,
      valueField: columns('calculations.eua.liability'),
      headerClassName: GridCustomHeaderClass.BorderLeft,
      renderCell: (params: any) => (
        <Tooltip
          title={formatNumber(params.row.calculations['eua']?.liability, 2)}
        >
          <span className='MuiDataGrid-cellContent pointer'>
            {formatNumber(params.row.calculations['eua']?.liability)}
          </span>
        </Tooltip>
      ),
      renderHeader: euaLiabilitiesTooltipHeader,
      valueFormatter: (params) => euaExcelDisplayValue(params),
    }),
    GridRagColumnValue({
      field: columns('calculations.eua.liabilityPercentage'),
      headerName: 'EUA \nAgainst Planned (%)',
      valueField: columns('calculations.eua.liabilityPercentage'),
      statusField: columns('calculations.eua.status'),
      visibility: (dataRow: any) => {
        if (!dataRow) return false;
        const row = dataRow as IFleetVessel;
        const rawValue = row.calculations.euaAgainstPlanned.value;
        const value = isNumeric(rawValue) ? Number(rawValue) : 0;
        return value > 0;
      },
    }),
    GridRagColumnValue({
      field: columns('calculations.euaAgainstFleet.value'),
      headerName: 'EUA \nAgainst Fleet (%)',
      valueField: columns('calculations.euaAgainstFleet.value'),
      statusField: columns('calculations.euaAgainstFleet.status'),
    }),
    GridColumnValue({
      field: columns('calculations.euaAgainstPlanned.value'),
      headerName: 'Planned \nVessel EUA',
      headerAlign: 'right',
      align: 'right',
      minWidth: SMALL_CELL_WIDTH,
      valueField: columns('calculations.euaAgainstPlanned.value'),
      noDecimals: 0,
    }),
  ];

  // we could add logic here to include/exclude sections
  let result = [...commonSection];
  result = result.concat(CIISection);
  result = result.concat(EEOISection);
  result = result.concat(AERSection);
  result = result.concat(otherSection);
  result = result.concat(EUASection);

  return result;
}

export const FleetOverviewTableGrid: React.FC<FleetOverviewTableProps> = ({
  dateRange,
}) => {
  const navigate = useNavigate();
  const columns = getColumnsDefinition(navigate);

  const { imoNumbers } = useContext(NavBarContext);
  const { loading, error, data } = useGetVessels(dateRange, imoNumbers);
  const extractKeysForFiltering = ({
    calculations: { aer, cii, eeoi, eua, euaAgainstFleet, euaAgainstPlanned },
  }: IFleetVessel) => ({
    aer,
    cii,
    eeoi,
    eua,
    euaAgainstFleet,
    euaAgainstPlanned,
  });
  const filterResult = useFilter<IFleetVessel>(
    data ?? [],
    extractKeysForFiltering
  );
  return (
    <CardContainer title={fleetPageTitles.vesselEnvironmentalKPIsComparison}>
      <RAGFilter
        activeFilters={filterResult.activeFilters}
        handleToggle={filterResult.handleFilter}
      ></RAGFilter>
      <DataGridTable
        name='EM-Fleet Breakdown by Vessels'
        rows={filterResult.filteredData}
        columns={columns}
        autoWidth={false}
        loading={loading}
        error={error}
      ></DataGridTable>
    </CardContainer>
  );
};

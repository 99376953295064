import { FeatureFlag } from '_gql/graphql';
import { FeatureFlagDomain } from 'shared/models/featureFlag.model';

export class FeatureFlagMapper {
  public static toDomain(data: FeatureFlag | undefined): FeatureFlagDomain {
    return {
      isEnabled: data?.isEnabled ?? false,
    };
  }
}

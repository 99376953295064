import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import { SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  ResponsiveContainer,
  Label,
} from 'recharts';
import { theme } from 'styles/theme';
import { CustomTooltip } from './eeoi-chart-tooltip.component';
import { LoadingComponent } from 'shared/components/loading/loading.component';
import { useGetFleetEeoiChart } from 'routes/environmental-monitor/services/fleet.service';
import { FixedTimeline } from 'assets/fixed-timeline';
import {
  getDomain,
  getXAxisTicks,
  yAxisTitle,
  xAxisTickFormatter,
} from 'routes/environmental-monitor/view-models/eeoi-chart.viewModel';
import { ErrorComponent } from 'shared/components/error/error.component';
import { fleetPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';

import { useContext } from 'react';

import { NavBarContext } from 'shared/models/navBar.context.model';
import { UTCDate } from 'shared/utils/date-utc-helper';

const xAxisTicks = getXAxisTicks();
const domain = getDomain();

const wrapperStyle: SxProps = {
  mt: 3,
  mb: 3,
  px: 3,
  py: 5,
  borderRadius: '6px',
  backgroundColor: theme.background.component,
};

const titleStyle: SxProps = {
  mb: 3,
  display: 'flex',
  justifyContent: 'center',
};

const keyStyle: SxProps = {
  ml: 4,
  mb: 3,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const iconStyle: SxProps = {
  ml: 1.5,
  mr: 0.5,
  fontSize: '18px',
};

const secondaryGreen = theme.environmentalMonitor.colors.cyan;
const axisColor = theme.vesselPerformance.charts.axis;

export function EEOIChart() {
  const { imoNumbers } = useContext(NavBarContext);
  const eeoiData = useGetFleetEeoiChart(imoNumbers);

  const renderContent = () => {
    if (eeoiData.loading) {
      return (
        <LoadingComponent data-testid='loading-component' height='415px' />
      );
    }

    const hasData = eeoiData?.data?.length > 0;

    // dates represent the last day of the month, or the first day of the next month.
    // we need the dates to be at the beginning of the month.
    eeoiData?.data?.forEach((x) => {
      let date = new UTCDate(x.date);
      date = date?.startOfMonth!;
      x.date = date.unixTime;
    });
    if (eeoiData.error || !hasData) {
      return <ErrorComponent error={eeoiData.error} empty={!hasData} />;
    }

    return (
      <>
        <Box sx={keyStyle}>
          <Typography variant='menuItem'>Key</Typography>
          <CircleIcon sx={{ ...iconStyle, color: secondaryGreen }} />
          <Typography variant='menuItemLight'>EEOI</Typography>
        </Box>
        <ResponsiveContainer width='99%' height={300}>
          <LineChart
            data={eeoiData.data}
            margin={{ top: 20, right: 10, bottom: 38, left: 10 }}
          >
            <XAxis
              dataKey='date'
              angle={-35}
              tick={{ fill: axisColor }}
              type='number'
              ticks={xAxisTicks}
              textAnchor='end'
              tickFormatter={xAxisTickFormatter}
              fontSize={12}
              domain={[domain.min, domain.max]}
              tickMargin={8}
            />
            <XAxis
              dataKey='date'
              dy={12}
              angle={-35}
              textAnchor='end'
              axisLine={false}
              tickLine={true}
              tick={{ fill: axisColor }}
              style={{ ...theme.typography.chart }}
            />
            <YAxis
              dataKey='eeoiDeviation'
              dx={-12}
              axisLine={false}
              tickLine={false}
              tick={{ fill: axisColor }}
              style={{ ...theme.typography.chart }}
              tickFormatter={(tick: number) => `${tick}%`}
            >
              <Label
                dy={80}
                fontSize={12}
                fill='#FFFFFF'
                position={'insideLeft'}
                angle={-90}
                value={yAxisTitle}
              />
            </YAxis>
            <CartesianGrid
              vertical={false}
              strokeDasharray='0'
              opacity={0.12}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type='linear'
              dot={false}
              dataKey='eeoiDeviation'
              stroke={secondaryGreen}
              connectNulls
              strokeWidth={3}
            />
          </LineChart>
        </ResponsiveContainer>
      </>
    );
  };

  return (
    <Box sx={wrapperStyle}>
      <Typography variant='h3' sx={titleStyle}>
        {fleetPageTitles.eeoi}
        <FixedTimeline sx={{ margin: '5px 0px 0px 15px' }} fillOpacity={0.5} />
      </Typography>
      {renderContent()}
    </Box>
  );
}

import { useContext } from 'react';
import PageWrapper from 'shared/components/page-wrapper/page-wrapper.component';
import { Section } from 'shared/components/section/section.component';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { PageHeader } from '../../shared/components/page-header/pageHeader.component';
import { CIIRatingsChart } from './components/fleet-cii-ratings/cii-ratings-chart.component';
import { EEOIChart } from './components/fleet-eeoi-chart/eeoi-chart.component';
import { FleetKpiDataSummary } from './components/fleet-kpi-data-summary/fleet-kpi-data-summary';
import { FleetOverviewTableGrid } from './components/fleet-overview/fleet-grid';
import { FleetUtilizationContainer } from './components/fleet-overview/fleet-utilization/wrapper';
import { fleetPageTitles } from './view-models/titles.viewModel';
import { DateRange } from 'shared/utils/date-utc-helper';
import { ExportableSections } from 'shared/utils/valid-sections';

export function EnvironmentalMonitorRoute() {
  const { utcDateRange, imoNumbers } = useContext(NavBarContext);
  const fleetCiiDateRange = DateRange.getLastAndCurrentYearDateRange();

  return (
    <PageWrapper data-testid={'environmental-monitor-fleet-overview-section'}>
      <PageHeader title={fleetPageTitles.environmentalMonitorFleetOverview} />

      <Section
        exportSection={true}
        exportOptions={{ includePDF: false, includeCardData: true }}
        title={fleetPageTitles.fleetKpis}
        id={ExportableSections['EM-Fleet KPIs']}
        childComponent={
          <>
            <FleetKpiDataSummary dateRange={utcDateRange} />
            <CIIRatingsChart dateRange={fleetCiiDateRange}></CIIRatingsChart>
            <FleetUtilizationContainer
              dateRange={utcDateRange}
              vesselImos={imoNumbers}
            ></FleetUtilizationContainer>
            <EEOIChart />
          </>
        }
      />

      <Section
        id='EM-Fleet Breakdown by Vessels'
        title={fleetPageTitles.fleetBreakdownByVessels}
        childComponent={<FleetOverviewTableGrid dateRange={utcDateRange} />}
        exportSection={true}
        exportOptions={{ includePDF: false }}
        preventUnmount={true}
      />
    </PageWrapper>
  );
}

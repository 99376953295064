import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import {
  euaActualStopsGenerator,
  generateReferenceAreaData,
} from '../../../view-models/eua-ratings-chart.viewModel';
import { theme } from 'styles/theme';

export const ColorBarGradientArea = (props: {
  euaPlannedValue: number;
  ytdTotal: number;
  stops: number[];
  yMax: number;
}) => {
  const plannedValue = props.euaPlannedValue;
  const stops = props.stops;
  const yMax = props.yMax;
  const actualStops = euaActualStopsGenerator(
    props.ytdTotal,
    stops,
    plannedValue
  );
  const euaBoundariesChartData = generateReferenceAreaData(
    plannedValue,
    actualStops,
    yMax
  );
  const colors = [
    theme.colors?.aRating,
    theme.colors?.bRating,
    theme.colors?.eRating,
  ];
  return (
    <ResponsiveContainer height={415} width='99%'>
      <AreaChart
        data={euaBoundariesChartData}
        margin={{ top: 20, bottom: 60, right: 25, left: 25 }}
      >
        <XAxis
          dataKey={'position'}
          axisLine={false}
          tickLine={false}
          tick={false}
        />
        <YAxis
          allowDataOverflow
          fontSize={12}
          dataKey='maxValue'
          tickLine={false}
          tick={false}
          tickCount={8}
          tickMargin={0}
          type='number'
          domain={[0, yMax]}
          axisLine={false}
          dx={-12}
          width={75}
        />
        {colors.map((fill, index) => {
          return (
            <svg key={index}>
              <defs>
                return (
                <linearGradient
                  key={fill}
                  id={'grad-' + fill}
                  x1='0%'
                  y1='0%'
                  x2='100%'
                  y2='0%'
                >
                  <stop
                    offset='0%'
                    style={{ stopColor: fill, stopOpacity: 0.5 }}
                  />
                  <stop
                    offset={actualStops[0]?.toString() + '%'}
                    style={{
                      stopColor: theme.background.page,
                      stopOpacity: 0,
                    }}
                  />
                  <stop
                    offset={actualStops[1]?.toString() + '%'}
                    style={{
                      stopColor: theme.background.page,
                      stopOpacity: 0,
                    }}
                  />
                  <stop
                    offset='100%'
                    style={{ stopColor: fill, stopOpacity: 0.5 }}
                  />
                </linearGradient>
                );
              </defs>
            </svg>
          );
        })}

        <Area
          type='monotone'
          dataKey='aValue'
          stackId='1'
          stroke='none'
          fill={'url(#grad-' + colors[0] + ')'}
        />
        <Area
          type='monotone'
          dataKey='bValue'
          stackId='1'
          stroke='none'
          fill={'url(#grad-' + colors[1] + ')'}
        />
        <Area
          type='monotone'
          dataKey='cValue'
          stackId='1'
          stroke='none'
          fill={'url(#grad-' + colors[2] + ')'}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
